/*
Template Name: Hyper - Responsive Bootstrap 4 Admin Dashboard
Version: 1.2.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/
@font-face {
  font-family: "dripicons-v2";
  src: url("../fonts/dripicons-v2.eot");
  src: url("../fonts/dripicons-v2.eot?#iefix") format("embedded-opentype"), url("../fonts/dripicons-v2.woff") format("woff"), url("../fonts/dripicons-v2.ttf") format("truetype"), url("../fonts/dripicons-v2.svg#dripicons-v2") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="dripicons-"]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle; }

.dripicons-alarm:before {
  content: "\61"; }

.dripicons-align-center:before {
  content: "\62"; }

.dripicons-align-justify:before {
  content: "\63"; }

.dripicons-align-left:before {
  content: "\64"; }

.dripicons-align-right:before {
  content: "\65"; }

.dripicons-anchor:before {
  content: "\66"; }

.dripicons-archive:before {
  content: "\67"; }

.dripicons-arrow-down:before {
  content: "\68"; }

.dripicons-arrow-left:before {
  content: "\69"; }

.dripicons-arrow-right:before {
  content: "\6a"; }

.dripicons-arrow-thin-down:before {
  content: "\6b"; }

.dripicons-arrow-thin-left:before {
  content: "\6c"; }

.dripicons-arrow-thin-right:before {
  content: "\6d"; }

.dripicons-arrow-thin-up:before {
  content: "\6e"; }

.dripicons-arrow-up:before {
  content: "\6f"; }

.dripicons-article:before {
  content: "\70"; }

.dripicons-backspace:before {
  content: "\71"; }

.dripicons-basket:before {
  content: "\72"; }

.dripicons-basketball:before {
  content: "\73"; }

.dripicons-battery-empty:before {
  content: "\74"; }

.dripicons-battery-full:before {
  content: "\75"; }

.dripicons-battery-low:before {
  content: "\76"; }

.dripicons-battery-medium:before {
  content: "\77"; }

.dripicons-bell:before {
  content: "\78"; }

.dripicons-blog:before {
  content: "\79"; }

.dripicons-bluetooth:before {
  content: "\7a"; }

.dripicons-bold:before {
  content: "\41"; }

.dripicons-bookmark:before {
  content: "\42"; }

.dripicons-bookmarks:before {
  content: "\43"; }

.dripicons-box:before {
  content: "\44"; }

.dripicons-briefcase:before {
  content: "\45"; }

.dripicons-brightness-low:before {
  content: "\46"; }

.dripicons-brightness-max:before {
  content: "\47"; }

.dripicons-brightness-medium:before {
  content: "\48"; }

.dripicons-broadcast:before {
  content: "\49"; }

.dripicons-browser:before {
  content: "\4a"; }

.dripicons-browser-upload:before {
  content: "\4b"; }

.dripicons-brush:before {
  content: "\4c"; }

.dripicons-calendar:before {
  content: "\4d"; }

.dripicons-camcorder:before {
  content: "\4e"; }

.dripicons-camera:before {
  content: "\4f"; }

.dripicons-card:before {
  content: "\50"; }

.dripicons-cart:before {
  content: "\51"; }

.dripicons-checklist:before {
  content: "\52"; }

.dripicons-checkmark:before {
  content: "\53"; }

.dripicons-chevron-down:before {
  content: "\54"; }

.dripicons-chevron-left:before {
  content: "\55"; }

.dripicons-chevron-right:before {
  content: "\56"; }

.dripicons-chevron-up:before {
  content: "\57"; }

.dripicons-clipboard:before {
  content: "\58"; }

.dripicons-clock:before {
  content: "\59"; }

.dripicons-clockwise:before {
  content: "\5a"; }

.dripicons-cloud:before {
  content: "\30"; }

.dripicons-cloud-download:before {
  content: "\31"; }

.dripicons-cloud-upload:before {
  content: "\32"; }

.dripicons-code:before {
  content: "\33"; }

.dripicons-contract:before {
  content: "\34"; }

.dripicons-contract-2:before {
  content: "\35"; }

.dripicons-conversation:before {
  content: "\36"; }

.dripicons-copy:before {
  content: "\37"; }

.dripicons-crop:before {
  content: "\38"; }

.dripicons-cross:before {
  content: "\39"; }

.dripicons-crosshair:before {
  content: "\21"; }

.dripicons-cutlery:before {
  content: "\22"; }

.dripicons-device-desktop:before {
  content: "\23"; }

.dripicons-device-mobile:before {
  content: "\24"; }

.dripicons-device-tablet:before {
  content: "\25"; }

.dripicons-direction:before {
  content: "\26"; }

.dripicons-disc:before {
  content: "\27"; }

.dripicons-document:before {
  content: "\28"; }

.dripicons-document-delete:before {
  content: "\29"; }

.dripicons-document-edit:before {
  content: "\2a"; }

.dripicons-document-new:before {
  content: "\2b"; }

.dripicons-document-remove:before {
  content: "\2c"; }

.dripicons-dot:before {
  content: "\2d"; }

.dripicons-dots-2:before {
  content: "\2e"; }

.dripicons-dots-3:before {
  content: "\2f"; }

.dripicons-download:before {
  content: "\3a"; }

.dripicons-duplicate:before {
  content: "\3b"; }

.dripicons-enter:before {
  content: "\3c"; }

.dripicons-exit:before {
  content: "\3d"; }

.dripicons-expand:before {
  content: "\3e"; }

.dripicons-expand-2:before {
  content: "\3f"; }

.dripicons-experiment:before {
  content: "\40"; }

.dripicons-export:before {
  content: "\5b"; }

.dripicons-feed:before {
  content: "\5d"; }

.dripicons-flag:before {
  content: "\5e"; }

.dripicons-flashlight:before {
  content: "\5f"; }

.dripicons-folder:before {
  content: "\60"; }

.dripicons-folder-open:before {
  content: "\7b"; }

.dripicons-forward:before {
  content: "\7c"; }

.dripicons-gaming:before {
  content: "\7d"; }

.dripicons-gear:before {
  content: "\7e"; }

.dripicons-graduation:before {
  content: "\5c"; }

.dripicons-graph-bar:before {
  content: "\e000"; }

.dripicons-graph-line:before {
  content: "\e001"; }

.dripicons-graph-pie:before {
  content: "\e002"; }

.dripicons-headset:before {
  content: "\e003"; }

.dripicons-heart:before {
  content: "\e004"; }

.dripicons-help:before {
  content: "\e005"; }

.dripicons-home:before {
  content: "\e006"; }

.dripicons-hourglass:before {
  content: "\e007"; }

.dripicons-inbox:before {
  content: "\e008"; }

.dripicons-information:before {
  content: "\e009"; }

.dripicons-italic:before {
  content: "\e00a"; }

.dripicons-jewel:before {
  content: "\e00b"; }

.dripicons-lifting:before {
  content: "\e00c"; }

.dripicons-lightbulb:before {
  content: "\e00d"; }

.dripicons-link:before {
  content: "\e00e"; }

.dripicons-link-broken:before {
  content: "\e00f"; }

.dripicons-list:before {
  content: "\e010"; }

.dripicons-loading:before {
  content: "\e011"; }

.dripicons-location:before {
  content: "\e012"; }

.dripicons-lock:before {
  content: "\e013"; }

.dripicons-lock-open:before {
  content: "\e014"; }

.dripicons-mail:before {
  content: "\e015"; }

.dripicons-map:before {
  content: "\e016"; }

.dripicons-media-loop:before {
  content: "\e017"; }

.dripicons-media-next:before {
  content: "\e018"; }

.dripicons-media-pause:before {
  content: "\e019"; }

.dripicons-media-play:before {
  content: "\e01a"; }

.dripicons-media-previous:before {
  content: "\e01b"; }

.dripicons-media-record:before {
  content: "\e01c"; }

.dripicons-media-shuffle:before {
  content: "\e01d"; }

.dripicons-media-stop:before {
  content: "\e01e"; }

.dripicons-medical:before {
  content: "\e01f"; }

.dripicons-menu:before {
  content: "\e020"; }

.dripicons-message:before {
  content: "\e021"; }

.dripicons-meter:before {
  content: "\e022"; }

.dripicons-microphone:before {
  content: "\e023"; }

.dripicons-minus:before {
  content: "\e024"; }

.dripicons-monitor:before {
  content: "\e025"; }

.dripicons-move:before {
  content: "\e026"; }

.dripicons-music:before {
  content: "\e027"; }

.dripicons-network-1:before {
  content: "\e028"; }

.dripicons-network-2:before {
  content: "\e029"; }

.dripicons-network-3:before {
  content: "\e02a"; }

.dripicons-network-4:before {
  content: "\e02b"; }

.dripicons-network-5:before {
  content: "\e02c"; }

.dripicons-pamphlet:before {
  content: "\e02d"; }

.dripicons-paperclip:before {
  content: "\e02e"; }

.dripicons-pencil:before {
  content: "\e02f"; }

.dripicons-phone:before {
  content: "\e030"; }

.dripicons-photo:before {
  content: "\e031"; }

.dripicons-photo-group:before {
  content: "\e032"; }

.dripicons-pill:before {
  content: "\e033"; }

.dripicons-pin:before {
  content: "\e034"; }

.dripicons-plus:before {
  content: "\e035"; }

.dripicons-power:before {
  content: "\e036"; }

.dripicons-preview:before {
  content: "\e037"; }

.dripicons-print:before {
  content: "\e038"; }

.dripicons-pulse:before {
  content: "\e039"; }

.dripicons-question:before {
  content: "\e03a"; }

.dripicons-reply:before {
  content: "\e03b"; }

.dripicons-reply-all:before {
  content: "\e03c"; }

.dripicons-return:before {
  content: "\e03d"; }

.dripicons-retweet:before {
  content: "\e03e"; }

.dripicons-rocket:before {
  content: "\e03f"; }

.dripicons-scale:before {
  content: "\e040"; }

.dripicons-search:before {
  content: "\e041"; }

.dripicons-shopping-bag:before {
  content: "\e042"; }

.dripicons-skip:before {
  content: "\e043"; }

.dripicons-stack:before {
  content: "\e044"; }

.dripicons-star:before {
  content: "\e045"; }

.dripicons-stopwatch:before {
  content: "\e046"; }

.dripicons-store:before {
  content: "\e047"; }

.dripicons-suitcase:before {
  content: "\e048"; }

.dripicons-swap:before {
  content: "\e049"; }

.dripicons-tag:before {
  content: "\e04a"; }

.dripicons-tag-delete:before {
  content: "\e04b"; }

.dripicons-tags:before {
  content: "\e04c"; }

.dripicons-thumbs-down:before {
  content: "\e04d"; }

.dripicons-thumbs-up:before {
  content: "\e04e"; }

.dripicons-ticket:before {
  content: "\e04f"; }

.dripicons-time-reverse:before {
  content: "\e050"; }

.dripicons-to-do:before {
  content: "\e051"; }

.dripicons-toggles:before {
  content: "\e052"; }

.dripicons-trash:before {
  content: "\e053"; }

.dripicons-trophy:before {
  content: "\e054"; }

.dripicons-upload:before {
  content: "\e055"; }

.dripicons-user:before {
  content: "\e056"; }

.dripicons-user-group:before {
  content: "\e057"; }

.dripicons-user-id:before {
  content: "\e058"; }

.dripicons-vibrate:before {
  content: "\e059"; }

.dripicons-view-apps:before {
  content: "\e05a"; }

.dripicons-view-list:before {
  content: "\e05b"; }

.dripicons-view-list-large:before {
  content: "\e05c"; }

.dripicons-view-thumb:before {
  content: "\e05d"; }

.dripicons-volume-full:before {
  content: "\e05e"; }

.dripicons-volume-low:before {
  content: "\e05f"; }

.dripicons-volume-medium:before {
  content: "\e060"; }

.dripicons-volume-off:before {
  content: "\e061"; }

.dripicons-wallet:before {
  content: "\e062"; }

.dripicons-warning:before {
  content: "\e063"; }

.dripicons-web:before {
  content: "\e064"; }

.dripicons-weight:before {
  content: "\e065"; }

.dripicons-wifi:before {
  content: "\e066"; }

.dripicons-wrong:before {
  content: "\e067"; }

.dripicons-zoom-in:before {
  content: "\e068"; }

.dripicons-zoom-out:before {
  content: "\e069"; }

/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot");
  src: url("../fonts/materialdesignicons-webfont.eot") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2") format("woff2"), url("../fonts/materialdesignicons-webfont.woff") format("woff"), url("../fonts/materialdesignicons-webfont.ttf") format("truetype"), url("../fonts/materialdesignicons-webfont.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 20px;
  line-height: 20px;
  text-rendering: auto;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mdi-access-point:before {
  content: "\F002"; }

.mdi-access-point-network:before {
  content: "\F003"; }

.mdi-account:before {
  content: "\F004"; }

.mdi-account-alert:before {
  content: "\F005"; }

.mdi-account-box:before {
  content: "\F006"; }

.mdi-account-box-outline:before {
  content: "\F007"; }

.mdi-account-card-details:before {
  content: "\F5D2"; }

.mdi-account-check:before {
  content: "\F008"; }

.mdi-account-circle:before {
  content: "\F009"; }

.mdi-account-convert:before {
  content: "\F00A"; }

.mdi-account-edit:before {
  content: "\F6BB"; }

.mdi-account-group:before {
  content: "\F848"; }

.mdi-account-heart:before {
  content: "\F898"; }

.mdi-account-key:before {
  content: "\F00B"; }

.mdi-account-location:before {
  content: "\F00C"; }

.mdi-account-minus:before {
  content: "\F00D"; }

.mdi-account-multiple:before {
  content: "\F00E"; }

.mdi-account-multiple-check:before {
  content: "\F8C4"; }

.mdi-account-multiple-minus:before {
  content: "\F5D3"; }

.mdi-account-multiple-outline:before {
  content: "\F00F"; }

.mdi-account-multiple-plus:before {
  content: "\F010"; }

.mdi-account-multiple-plus-outline:before {
  content: "\F7FF"; }

.mdi-account-network:before {
  content: "\F011"; }

.mdi-account-off:before {
  content: "\F012"; }

.mdi-account-outline:before {
  content: "\F013"; }

.mdi-account-plus:before {
  content: "\F014"; }

.mdi-account-plus-outline:before {
  content: "\F800"; }

.mdi-account-remove:before {
  content: "\F015"; }

.mdi-account-search:before {
  content: "\F016"; }

.mdi-account-settings:before {
  content: "\F630"; }

.mdi-account-settings-variant:before {
  content: "\F631"; }

.mdi-account-star:before {
  content: "\F017"; }

.mdi-account-switch:before {
  content: "\F019"; }

.mdi-accusoft:before {
  content: "\F849"; }

.mdi-adjust:before {
  content: "\F01A"; }

.mdi-air-conditioner:before {
  content: "\F01B"; }

.mdi-airballoon:before {
  content: "\F01C"; }

.mdi-airplane:before {
  content: "\F01D"; }

.mdi-airplane-landing:before {
  content: "\F5D4"; }

.mdi-airplane-off:before {
  content: "\F01E"; }

.mdi-airplane-takeoff:before {
  content: "\F5D5"; }

.mdi-airplay:before {
  content: "\F01F"; }

.mdi-airport:before {
  content: "\F84A"; }

.mdi-alarm:before {
  content: "\F020"; }

.mdi-alarm-bell:before {
  content: "\F78D"; }

.mdi-alarm-check:before {
  content: "\F021"; }

.mdi-alarm-light:before {
  content: "\F78E"; }

.mdi-alarm-multiple:before {
  content: "\F022"; }

.mdi-alarm-off:before {
  content: "\F023"; }

.mdi-alarm-plus:before {
  content: "\F024"; }

.mdi-alarm-snooze:before {
  content: "\F68D"; }

.mdi-album:before {
  content: "\F025"; }

.mdi-alert:before {
  content: "\F026"; }

.mdi-alert-box:before {
  content: "\F027"; }

.mdi-alert-circle:before {
  content: "\F028"; }

.mdi-alert-circle-outline:before {
  content: "\F5D6"; }

.mdi-alert-decagram:before {
  content: "\F6BC"; }

.mdi-alert-octagon:before {
  content: "\F029"; }

.mdi-alert-octagram:before {
  content: "\F766"; }

.mdi-alert-outline:before {
  content: "\F02A"; }

.mdi-alien:before {
  content: "\F899"; }

.mdi-all-inclusive:before {
  content: "\F6BD"; }

.mdi-alpha:before {
  content: "\F02B"; }

.mdi-alphabetical:before {
  content: "\F02C"; }

.mdi-altimeter:before {
  content: "\F5D7"; }

.mdi-amazon:before {
  content: "\F02D"; }

.mdi-amazon-alexa:before {
  content: "\F8C5"; }

.mdi-amazon-drive:before {
  content: "\F02E"; }

.mdi-ambulance:before {
  content: "\F02F"; }

.mdi-amplifier:before {
  content: "\F030"; }

.mdi-anchor:before {
  content: "\F031"; }

.mdi-android:before {
  content: "\F032"; }

.mdi-android-debug-bridge:before {
  content: "\F033"; }

.mdi-android-head:before {
  content: "\F78F"; }

.mdi-android-studio:before {
  content: "\F034"; }

.mdi-angular:before {
  content: "\F6B1"; }

.mdi-angularjs:before {
  content: "\F6BE"; }

.mdi-animation:before {
  content: "\F5D8"; }

.mdi-anvil:before {
  content: "\F89A"; }

.mdi-apple:before {
  content: "\F035"; }

.mdi-apple-finder:before {
  content: "\F036"; }

.mdi-apple-icloud:before {
  content: "\F038"; }

.mdi-apple-ios:before {
  content: "\F037"; }

.mdi-apple-keyboard-caps:before {
  content: "\F632"; }

.mdi-apple-keyboard-command:before {
  content: "\F633"; }

.mdi-apple-keyboard-control:before {
  content: "\F634"; }

.mdi-apple-keyboard-option:before {
  content: "\F635"; }

.mdi-apple-keyboard-shift:before {
  content: "\F636"; }

.mdi-apple-safari:before {
  content: "\F039"; }

.mdi-application:before {
  content: "\F614"; }

.mdi-approval:before {
  content: "\F790"; }

.mdi-apps:before {
  content: "\F03B"; }

.mdi-arch:before {
  content: "\F8C6"; }

.mdi-archive:before {
  content: "\F03C"; }

.mdi-arrange-bring-forward:before {
  content: "\F03D"; }

.mdi-arrange-bring-to-front:before {
  content: "\F03E"; }

.mdi-arrange-send-backward:before {
  content: "\F03F"; }

.mdi-arrange-send-to-back:before {
  content: "\F040"; }

.mdi-arrow-all:before {
  content: "\F041"; }

.mdi-arrow-bottom-left:before {
  content: "\F042"; }

.mdi-arrow-bottom-right:before {
  content: "\F043"; }

.mdi-arrow-collapse:before {
  content: "\F615"; }

.mdi-arrow-collapse-all:before {
  content: "\F044"; }

.mdi-arrow-collapse-down:before {
  content: "\F791"; }

.mdi-arrow-collapse-horizontal:before {
  content: "\F84B"; }

.mdi-arrow-collapse-left:before {
  content: "\F792"; }

.mdi-arrow-collapse-right:before {
  content: "\F793"; }

.mdi-arrow-collapse-up:before {
  content: "\F794"; }

.mdi-arrow-collapse-vertical:before {
  content: "\F84C"; }

.mdi-arrow-down:before {
  content: "\F045"; }

.mdi-arrow-down-bold:before {
  content: "\F72D"; }

.mdi-arrow-down-bold-box:before {
  content: "\F72E"; }

.mdi-arrow-down-bold-box-outline:before {
  content: "\F72F"; }

.mdi-arrow-down-bold-circle:before {
  content: "\F047"; }

.mdi-arrow-down-bold-circle-outline:before {
  content: "\F048"; }

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\F049"; }

.mdi-arrow-down-box:before {
  content: "\F6BF"; }

.mdi-arrow-down-drop-circle:before {
  content: "\F04A"; }

.mdi-arrow-down-drop-circle-outline:before {
  content: "\F04B"; }

.mdi-arrow-down-thick:before {
  content: "\F046"; }

.mdi-arrow-expand:before {
  content: "\F616"; }

.mdi-arrow-expand-all:before {
  content: "\F04C"; }

.mdi-arrow-expand-down:before {
  content: "\F795"; }

.mdi-arrow-expand-horizontal:before {
  content: "\F84D"; }

.mdi-arrow-expand-left:before {
  content: "\F796"; }

.mdi-arrow-expand-right:before {
  content: "\F797"; }

.mdi-arrow-expand-up:before {
  content: "\F798"; }

.mdi-arrow-expand-vertical:before {
  content: "\F84E"; }

.mdi-arrow-left:before {
  content: "\F04D"; }

.mdi-arrow-left-bold:before {
  content: "\F730"; }

.mdi-arrow-left-bold-box:before {
  content: "\F731"; }

.mdi-arrow-left-bold-box-outline:before {
  content: "\F732"; }

.mdi-arrow-left-bold-circle:before {
  content: "\F04F"; }

.mdi-arrow-left-bold-circle-outline:before {
  content: "\F050"; }

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\F051"; }

.mdi-arrow-left-box:before {
  content: "\F6C0"; }

.mdi-arrow-left-drop-circle:before {
  content: "\F052"; }

.mdi-arrow-left-drop-circle-outline:before {
  content: "\F053"; }

.mdi-arrow-left-thick:before {
  content: "\F04E"; }

.mdi-arrow-right:before {
  content: "\F054"; }

.mdi-arrow-right-bold:before {
  content: "\F733"; }

.mdi-arrow-right-bold-box:before {
  content: "\F734"; }

.mdi-arrow-right-bold-box-outline:before {
  content: "\F735"; }

.mdi-arrow-right-bold-circle:before {
  content: "\F056"; }

.mdi-arrow-right-bold-circle-outline:before {
  content: "\F057"; }

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\F058"; }

.mdi-arrow-right-box:before {
  content: "\F6C1"; }

.mdi-arrow-right-drop-circle:before {
  content: "\F059"; }

.mdi-arrow-right-drop-circle-outline:before {
  content: "\F05A"; }

.mdi-arrow-right-thick:before {
  content: "\F055"; }

.mdi-arrow-top-left:before {
  content: "\F05B"; }

.mdi-arrow-top-right:before {
  content: "\F05C"; }

.mdi-arrow-up:before {
  content: "\F05D"; }

.mdi-arrow-up-bold:before {
  content: "\F736"; }

.mdi-arrow-up-bold-box:before {
  content: "\F737"; }

.mdi-arrow-up-bold-box-outline:before {
  content: "\F738"; }

.mdi-arrow-up-bold-circle:before {
  content: "\F05F"; }

.mdi-arrow-up-bold-circle-outline:before {
  content: "\F060"; }

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\F061"; }

.mdi-arrow-up-box:before {
  content: "\F6C2"; }

.mdi-arrow-up-drop-circle:before {
  content: "\F062"; }

.mdi-arrow-up-drop-circle-outline:before {
  content: "\F063"; }

.mdi-arrow-up-thick:before {
  content: "\F05E"; }

.mdi-artist:before {
  content: "\F802"; }

.mdi-assistant:before {
  content: "\F064"; }

.mdi-asterisk:before {
  content: "\F6C3"; }

.mdi-at:before {
  content: "\F065"; }

.mdi-atlassian:before {
  content: "\F803"; }

.mdi-atom:before {
  content: "\F767"; }

.mdi-attachment:before {
  content: "\F066"; }

.mdi-audiobook:before {
  content: "\F067"; }

.mdi-augmented-reality:before {
  content: "\F84F"; }

.mdi-auto-fix:before {
  content: "\F068"; }

.mdi-auto-upload:before {
  content: "\F069"; }

.mdi-autorenew:before {
  content: "\F06A"; }

.mdi-av-timer:before {
  content: "\F06B"; }

.mdi-axe:before {
  content: "\F8C7"; }

.mdi-azure:before {
  content: "\F804"; }

.mdi-baby:before {
  content: "\F06C"; }

.mdi-baby-buggy:before {
  content: "\F68E"; }

.mdi-backburger:before {
  content: "\F06D"; }

.mdi-backspace:before {
  content: "\F06E"; }

.mdi-backup-restore:before {
  content: "\F06F"; }

.mdi-badminton:before {
  content: "\F850"; }

.mdi-bandcamp:before {
  content: "\F674"; }

.mdi-bank:before {
  content: "\F070"; }

.mdi-barcode:before {
  content: "\F071"; }

.mdi-barcode-scan:before {
  content: "\F072"; }

.mdi-barley:before {
  content: "\F073"; }

.mdi-barrel:before {
  content: "\F074"; }

.mdi-baseball:before {
  content: "\F851"; }

.mdi-baseball-bat:before {
  content: "\F852"; }

.mdi-basecamp:before {
  content: "\F075"; }

.mdi-basket:before {
  content: "\F076"; }

.mdi-basket-fill:before {
  content: "\F077"; }

.mdi-basket-unfill:before {
  content: "\F078"; }

.mdi-basketball:before {
  content: "\F805"; }

.mdi-battery:before {
  content: "\F079"; }

.mdi-battery-10:before {
  content: "\F07A"; }

.mdi-battery-20:before {
  content: "\F07B"; }

.mdi-battery-30:before {
  content: "\F07C"; }

.mdi-battery-40:before {
  content: "\F07D"; }

.mdi-battery-50:before {
  content: "\F07E"; }

.mdi-battery-60:before {
  content: "\F07F"; }

.mdi-battery-70:before {
  content: "\F080"; }

.mdi-battery-80:before {
  content: "\F081"; }

.mdi-battery-90:before {
  content: "\F082"; }

.mdi-battery-alert:before {
  content: "\F083"; }

.mdi-battery-charging:before {
  content: "\F084"; }

.mdi-battery-charging-10:before {
  content: "\F89B"; }

.mdi-battery-charging-100:before {
  content: "\F085"; }

.mdi-battery-charging-20:before {
  content: "\F086"; }

.mdi-battery-charging-30:before {
  content: "\F087"; }

.mdi-battery-charging-40:before {
  content: "\F088"; }

.mdi-battery-charging-50:before {
  content: "\F89C"; }

.mdi-battery-charging-60:before {
  content: "\F089"; }

.mdi-battery-charging-70:before {
  content: "\F89D"; }

.mdi-battery-charging-80:before {
  content: "\F08A"; }

.mdi-battery-charging-90:before {
  content: "\F08B"; }

.mdi-battery-charging-outline:before {
  content: "\F89E"; }

.mdi-battery-charging-wireless:before {
  content: "\F806"; }

.mdi-battery-charging-wireless-10:before {
  content: "\F807"; }

.mdi-battery-charging-wireless-20:before {
  content: "\F808"; }

.mdi-battery-charging-wireless-30:before {
  content: "\F809"; }

.mdi-battery-charging-wireless-40:before {
  content: "\F80A"; }

.mdi-battery-charging-wireless-50:before {
  content: "\F80B"; }

.mdi-battery-charging-wireless-60:before {
  content: "\F80C"; }

.mdi-battery-charging-wireless-70:before {
  content: "\F80D"; }

.mdi-battery-charging-wireless-80:before {
  content: "\F80E"; }

.mdi-battery-charging-wireless-90:before {
  content: "\F80F"; }

.mdi-battery-charging-wireless-alert:before {
  content: "\F810"; }

.mdi-battery-charging-wireless-outline:before {
  content: "\F811"; }

.mdi-battery-minus:before {
  content: "\F08C"; }

.mdi-battery-negative:before {
  content: "\F08D"; }

.mdi-battery-outline:before {
  content: "\F08E"; }

.mdi-battery-plus:before {
  content: "\F08F"; }

.mdi-battery-positive:before {
  content: "\F090"; }

.mdi-battery-unknown:before {
  content: "\F091"; }

.mdi-beach:before {
  content: "\F092"; }

.mdi-beaker:before {
  content: "\F68F"; }

.mdi-beats:before {
  content: "\F097"; }

.mdi-bed-empty:before {
  content: "\F89F"; }

.mdi-beer:before {
  content: "\F098"; }

.mdi-behance:before {
  content: "\F099"; }

.mdi-bell:before {
  content: "\F09A"; }

.mdi-bell-off:before {
  content: "\F09B"; }

.mdi-bell-outline:before {
  content: "\F09C"; }

.mdi-bell-plus:before {
  content: "\F09D"; }

.mdi-bell-ring:before {
  content: "\F09E"; }

.mdi-bell-ring-outline:before {
  content: "\F09F"; }

.mdi-bell-sleep:before {
  content: "\F0A0"; }

.mdi-beta:before {
  content: "\F0A1"; }

.mdi-bible:before {
  content: "\F0A2"; }

.mdi-bike:before {
  content: "\F0A3"; }

.mdi-bing:before {
  content: "\F0A4"; }

.mdi-binoculars:before {
  content: "\F0A5"; }

.mdi-bio:before {
  content: "\F0A6"; }

.mdi-biohazard:before {
  content: "\F0A7"; }

.mdi-bitbucket:before {
  content: "\F0A8"; }

.mdi-bitcoin:before {
  content: "\F812"; }

.mdi-black-mesa:before {
  content: "\F0A9"; }

.mdi-blackberry:before {
  content: "\F0AA"; }

.mdi-blender:before {
  content: "\F0AB"; }

.mdi-blinds:before {
  content: "\F0AC"; }

.mdi-block-helper:before {
  content: "\F0AD"; }

.mdi-blogger:before {
  content: "\F0AE"; }

.mdi-bluetooth:before {
  content: "\F0AF"; }

.mdi-bluetooth-audio:before {
  content: "\F0B0"; }

.mdi-bluetooth-connect:before {
  content: "\F0B1"; }

.mdi-bluetooth-off:before {
  content: "\F0B2"; }

.mdi-bluetooth-settings:before {
  content: "\F0B3"; }

.mdi-bluetooth-transfer:before {
  content: "\F0B4"; }

.mdi-blur:before {
  content: "\F0B5"; }

.mdi-blur-linear:before {
  content: "\F0B6"; }

.mdi-blur-off:before {
  content: "\F0B7"; }

.mdi-blur-radial:before {
  content: "\F0B8"; }

.mdi-bomb:before {
  content: "\F690"; }

.mdi-bomb-off:before {
  content: "\F6C4"; }

.mdi-bone:before {
  content: "\F0B9"; }

.mdi-book:before {
  content: "\F0BA"; }

.mdi-book-minus:before {
  content: "\F5D9"; }

.mdi-book-multiple:before {
  content: "\F0BB"; }

.mdi-book-multiple-variant:before {
  content: "\F0BC"; }

.mdi-book-open:before {
  content: "\F0BD"; }

.mdi-book-open-page-variant:before {
  content: "\F5DA"; }

.mdi-book-open-variant:before {
  content: "\F0BE"; }

.mdi-book-plus:before {
  content: "\F5DB"; }

.mdi-book-secure:before {
  content: "\F799"; }

.mdi-book-unsecure:before {
  content: "\F79A"; }

.mdi-book-variant:before {
  content: "\F0BF"; }

.mdi-bookmark:before {
  content: "\F0C0"; }

.mdi-bookmark-check:before {
  content: "\F0C1"; }

.mdi-bookmark-music:before {
  content: "\F0C2"; }

.mdi-bookmark-outline:before {
  content: "\F0C3"; }

.mdi-bookmark-plus:before {
  content: "\F0C5"; }

.mdi-bookmark-plus-outline:before {
  content: "\F0C4"; }

.mdi-bookmark-remove:before {
  content: "\F0C6"; }

.mdi-boombox:before {
  content: "\F5DC"; }

.mdi-bootstrap:before {
  content: "\F6C5"; }

.mdi-border-all:before {
  content: "\F0C7"; }

.mdi-border-all-variant:before {
  content: "\F8A0"; }

.mdi-border-bottom:before {
  content: "\F0C8"; }

.mdi-border-bottom-variant:before {
  content: "\F8A1"; }

.mdi-border-color:before {
  content: "\F0C9"; }

.mdi-border-horizontal:before {
  content: "\F0CA"; }

.mdi-border-inside:before {
  content: "\F0CB"; }

.mdi-border-left:before {
  content: "\F0CC"; }

.mdi-border-left-variant:before {
  content: "\F8A2"; }

.mdi-border-none:before {
  content: "\F0CD"; }

.mdi-border-none-variant:before {
  content: "\F8A3"; }

.mdi-border-outside:before {
  content: "\F0CE"; }

.mdi-border-right:before {
  content: "\F0CF"; }

.mdi-border-right-variant:before {
  content: "\F8A4"; }

.mdi-border-style:before {
  content: "\F0D0"; }

.mdi-border-top:before {
  content: "\F0D1"; }

.mdi-border-top-variant:before {
  content: "\F8A5"; }

.mdi-border-vertical:before {
  content: "\F0D2"; }

.mdi-bottle-wine:before {
  content: "\F853"; }

.mdi-bow-tie:before {
  content: "\F677"; }

.mdi-bowl:before {
  content: "\F617"; }

.mdi-bowling:before {
  content: "\F0D3"; }

.mdi-box:before {
  content: "\F0D4"; }

.mdi-box-cutter:before {
  content: "\F0D5"; }

.mdi-box-shadow:before {
  content: "\F637"; }

.mdi-bridge:before {
  content: "\F618"; }

.mdi-briefcase:before {
  content: "\F0D6"; }

.mdi-briefcase-check:before {
  content: "\F0D7"; }

.mdi-briefcase-download:before {
  content: "\F0D8"; }

.mdi-briefcase-outline:before {
  content: "\F813"; }

.mdi-briefcase-upload:before {
  content: "\F0D9"; }

.mdi-brightness-1:before {
  content: "\F0DA"; }

.mdi-brightness-2:before {
  content: "\F0DB"; }

.mdi-brightness-3:before {
  content: "\F0DC"; }

.mdi-brightness-4:before {
  content: "\F0DD"; }

.mdi-brightness-5:before {
  content: "\F0DE"; }

.mdi-brightness-6:before {
  content: "\F0DF"; }

.mdi-brightness-7:before {
  content: "\F0E0"; }

.mdi-brightness-auto:before {
  content: "\F0E1"; }

.mdi-broom:before {
  content: "\F0E2"; }

.mdi-brush:before {
  content: "\F0E3"; }

.mdi-buffer:before {
  content: "\F619"; }

.mdi-bug:before {
  content: "\F0E4"; }

.mdi-bulletin-board:before {
  content: "\F0E5"; }

.mdi-bullhorn:before {
  content: "\F0E6"; }

.mdi-bullseye:before {
  content: "\F5DD"; }

.mdi-bullseye-arrow:before {
  content: "\F8C8"; }

.mdi-bus:before {
  content: "\F0E7"; }

.mdi-bus-articulated-end:before {
  content: "\F79B"; }

.mdi-bus-articulated-front:before {
  content: "\F79C"; }

.mdi-bus-clock:before {
  content: "\F8C9"; }

.mdi-bus-double-decker:before {
  content: "\F79D"; }

.mdi-bus-school:before {
  content: "\F79E"; }

.mdi-bus-side:before {
  content: "\F79F"; }

.mdi-cached:before {
  content: "\F0E8"; }

.mdi-cake:before {
  content: "\F0E9"; }

.mdi-cake-layered:before {
  content: "\F0EA"; }

.mdi-cake-variant:before {
  content: "\F0EB"; }

.mdi-calculator:before {
  content: "\F0EC"; }

.mdi-calendar:before {
  content: "\F0ED"; }

.mdi-calendar-blank:before {
  content: "\F0EE"; }

.mdi-calendar-check:before {
  content: "\F0EF"; }

.mdi-calendar-clock:before {
  content: "\F0F0"; }

.mdi-calendar-edit:before {
  content: "\F8A6"; }

.mdi-calendar-multiple:before {
  content: "\F0F1"; }

.mdi-calendar-multiple-check:before {
  content: "\F0F2"; }

.mdi-calendar-plus:before {
  content: "\F0F3"; }

.mdi-calendar-question:before {
  content: "\F691"; }

.mdi-calendar-range:before {
  content: "\F678"; }

.mdi-calendar-remove:before {
  content: "\F0F4"; }

.mdi-calendar-text:before {
  content: "\F0F5"; }

.mdi-calendar-today:before {
  content: "\F0F6"; }

.mdi-call-made:before {
  content: "\F0F7"; }

.mdi-call-merge:before {
  content: "\F0F8"; }

.mdi-call-missed:before {
  content: "\F0F9"; }

.mdi-call-received:before {
  content: "\F0FA"; }

.mdi-call-split:before {
  content: "\F0FB"; }

.mdi-camcorder:before {
  content: "\F0FC"; }

.mdi-camcorder-box:before {
  content: "\F0FD"; }

.mdi-camcorder-box-off:before {
  content: "\F0FE"; }

.mdi-camcorder-off:before {
  content: "\F0FF"; }

.mdi-camera:before {
  content: "\F100"; }

.mdi-camera-account:before {
  content: "\F8CA"; }

.mdi-camera-burst:before {
  content: "\F692"; }

.mdi-camera-enhance:before {
  content: "\F101"; }

.mdi-camera-front:before {
  content: "\F102"; }

.mdi-camera-front-variant:before {
  content: "\F103"; }

.mdi-camera-gopro:before {
  content: "\F7A0"; }

.mdi-camera-image:before {
  content: "\F8CB"; }

.mdi-camera-iris:before {
  content: "\F104"; }

.mdi-camera-metering-center:before {
  content: "\F7A1"; }

.mdi-camera-metering-matrix:before {
  content: "\F7A2"; }

.mdi-camera-metering-partial:before {
  content: "\F7A3"; }

.mdi-camera-metering-spot:before {
  content: "\F7A4"; }

.mdi-camera-off:before {
  content: "\F5DF"; }

.mdi-camera-party-mode:before {
  content: "\F105"; }

.mdi-camera-rear:before {
  content: "\F106"; }

.mdi-camera-rear-variant:before {
  content: "\F107"; }

.mdi-camera-switch:before {
  content: "\F108"; }

.mdi-camera-timer:before {
  content: "\F109"; }

.mdi-cancel:before {
  content: "\F739"; }

.mdi-candle:before {
  content: "\F5E2"; }

.mdi-candycane:before {
  content: "\F10A"; }

.mdi-cannabis:before {
  content: "\F7A5"; }

.mdi-car:before {
  content: "\F10B"; }

.mdi-car-battery:before {
  content: "\F10C"; }

.mdi-car-connected:before {
  content: "\F10D"; }

.mdi-car-convertible:before {
  content: "\F7A6"; }

.mdi-car-estate:before {
  content: "\F7A7"; }

.mdi-car-hatchback:before {
  content: "\F7A8"; }

.mdi-car-limousine:before {
  content: "\F8CC"; }

.mdi-car-pickup:before {
  content: "\F7A9"; }

.mdi-car-side:before {
  content: "\F7AA"; }

.mdi-car-sports:before {
  content: "\F7AB"; }

.mdi-car-wash:before {
  content: "\F10E"; }

.mdi-caravan:before {
  content: "\F7AC"; }

.mdi-cards:before {
  content: "\F638"; }

.mdi-cards-club:before {
  content: "\F8CD"; }

.mdi-cards-diamond:before {
  content: "\F8CE"; }

.mdi-cards-heart:before {
  content: "\F8CF"; }

.mdi-cards-outline:before {
  content: "\F639"; }

.mdi-cards-playing-outline:before {
  content: "\F63A"; }

.mdi-cards-spade:before {
  content: "\F8D0"; }

.mdi-cards-variant:before {
  content: "\F6C6"; }

.mdi-carrot:before {
  content: "\F10F"; }

.mdi-cart:before {
  content: "\F110"; }

.mdi-cart-off:before {
  content: "\F66B"; }

.mdi-cart-outline:before {
  content: "\F111"; }

.mdi-cart-plus:before {
  content: "\F112"; }

.mdi-case-sensitive-alt:before {
  content: "\F113"; }

.mdi-cash:before {
  content: "\F114"; }

.mdi-cash-100:before {
  content: "\F115"; }

.mdi-cash-multiple:before {
  content: "\F116"; }

.mdi-cash-usd:before {
  content: "\F117"; }

.mdi-cast:before {
  content: "\F118"; }

.mdi-cast-connected:before {
  content: "\F119"; }

.mdi-cast-off:before {
  content: "\F789"; }

.mdi-castle:before {
  content: "\F11A"; }

.mdi-cat:before {
  content: "\F11B"; }

.mdi-cctv:before {
  content: "\F7AD"; }

.mdi-ceiling-light:before {
  content: "\F768"; }

.mdi-cellphone:before {
  content: "\F11C"; }

.mdi-cellphone-android:before {
  content: "\F11D"; }

.mdi-cellphone-basic:before {
  content: "\F11E"; }

.mdi-cellphone-dock:before {
  content: "\F11F"; }

.mdi-cellphone-iphone:before {
  content: "\F120"; }

.mdi-cellphone-link:before {
  content: "\F121"; }

.mdi-cellphone-link-off:before {
  content: "\F122"; }

.mdi-cellphone-message:before {
  content: "\F8D2"; }

.mdi-cellphone-settings:before {
  content: "\F123"; }

.mdi-cellphone-text:before {
  content: "\F8D1"; }

.mdi-cellphone-wireless:before {
  content: "\F814"; }

.mdi-certificate:before {
  content: "\F124"; }

.mdi-chair-school:before {
  content: "\F125"; }

.mdi-chart-arc:before {
  content: "\F126"; }

.mdi-chart-areaspline:before {
  content: "\F127"; }

.mdi-chart-bar:before {
  content: "\F128"; }

.mdi-chart-bar-stacked:before {
  content: "\F769"; }

.mdi-chart-bubble:before {
  content: "\F5E3"; }

.mdi-chart-donut:before {
  content: "\F7AE"; }

.mdi-chart-donut-variant:before {
  content: "\F7AF"; }

.mdi-chart-gantt:before {
  content: "\F66C"; }

.mdi-chart-histogram:before {
  content: "\F129"; }

.mdi-chart-line:before {
  content: "\F12A"; }

.mdi-chart-line-stacked:before {
  content: "\F76A"; }

.mdi-chart-line-variant:before {
  content: "\F7B0"; }

.mdi-chart-multiline:before {
  content: "\F8D3"; }

.mdi-chart-pie:before {
  content: "\F12B"; }

.mdi-chart-scatterplot-hexbin:before {
  content: "\F66D"; }

.mdi-chart-timeline:before {
  content: "\F66E"; }

.mdi-check:before {
  content: "\F12C"; }

.mdi-check-all:before {
  content: "\F12D"; }

.mdi-check-circle:before {
  content: "\F5E0"; }

.mdi-check-circle-outline:before {
  content: "\F5E1"; }

.mdi-check-outline:before {
  content: "\F854"; }

.mdi-checkbox-blank:before {
  content: "\F12E"; }

.mdi-checkbox-blank-circle:before {
  content: "\F12F"; }

.mdi-checkbox-blank-circle-outline:before {
  content: "\F130"; }

.mdi-checkbox-blank-outline:before {
  content: "\F131"; }

.mdi-checkbox-intermediate:before {
  content: "\F855"; }

.mdi-checkbox-marked:before {
  content: "\F132"; }

.mdi-checkbox-marked-circle:before {
  content: "\F133"; }

.mdi-checkbox-marked-circle-outline:before {
  content: "\F134"; }

.mdi-checkbox-marked-outline:before {
  content: "\F135"; }

.mdi-checkbox-multiple-blank:before {
  content: "\F136"; }

.mdi-checkbox-multiple-blank-circle:before {
  content: "\F63B"; }

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\F63C"; }

.mdi-checkbox-multiple-blank-outline:before {
  content: "\F137"; }

.mdi-checkbox-multiple-marked:before {
  content: "\F138"; }

.mdi-checkbox-multiple-marked-circle:before {
  content: "\F63D"; }

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\F63E"; }

.mdi-checkbox-multiple-marked-outline:before {
  content: "\F139"; }

.mdi-checkerboard:before {
  content: "\F13A"; }

.mdi-chemical-weapon:before {
  content: "\F13B"; }

.mdi-chess-bishop:before {
  content: "\F85B"; }

.mdi-chess-king:before {
  content: "\F856"; }

.mdi-chess-knight:before {
  content: "\F857"; }

.mdi-chess-pawn:before {
  content: "\F858"; }

.mdi-chess-queen:before {
  content: "\F859"; }

.mdi-chess-rook:before {
  content: "\F85A"; }

.mdi-chevron-double-down:before {
  content: "\F13C"; }

.mdi-chevron-double-left:before {
  content: "\F13D"; }

.mdi-chevron-double-right:before {
  content: "\F13E"; }

.mdi-chevron-double-up:before {
  content: "\F13F"; }

.mdi-chevron-down:before {
  content: "\F140"; }

.mdi-chevron-left:before {
  content: "\F141"; }

.mdi-chevron-right:before {
  content: "\F142"; }

.mdi-chevron-up:before {
  content: "\F143"; }

.mdi-chili-hot:before {
  content: "\F7B1"; }

.mdi-chili-medium:before {
  content: "\F7B2"; }

.mdi-chili-mild:before {
  content: "\F7B3"; }

.mdi-chip:before {
  content: "\F61A"; }

.mdi-church:before {
  content: "\F144"; }

.mdi-circle:before {
  content: "\F764"; }

.mdi-circle-edit-outline:before {
  content: "\F8D4"; }

.mdi-circle-outline:before {
  content: "\F765"; }

.mdi-cisco-webex:before {
  content: "\F145"; }

.mdi-city:before {
  content: "\F146"; }

.mdi-clipboard:before {
  content: "\F147"; }

.mdi-clipboard-account:before {
  content: "\F148"; }

.mdi-clipboard-alert:before {
  content: "\F149"; }

.mdi-clipboard-arrow-down:before {
  content: "\F14A"; }

.mdi-clipboard-arrow-left:before {
  content: "\F14B"; }

.mdi-clipboard-check:before {
  content: "\F14C"; }

.mdi-clipboard-check-outline:before {
  content: "\F8A7"; }

.mdi-clipboard-flow:before {
  content: "\F6C7"; }

.mdi-clipboard-outline:before {
  content: "\F14D"; }

.mdi-clipboard-plus:before {
  content: "\F750"; }

.mdi-clipboard-pulse:before {
  content: "\F85C"; }

.mdi-clipboard-pulse-outline:before {
  content: "\F85D"; }

.mdi-clipboard-text:before {
  content: "\F14E"; }

.mdi-clippy:before {
  content: "\F14F"; }

.mdi-clock:before {
  content: "\F150"; }

.mdi-clock-alert:before {
  content: "\F5CE"; }

.mdi-clock-end:before {
  content: "\F151"; }

.mdi-clock-fast:before {
  content: "\F152"; }

.mdi-clock-in:before {
  content: "\F153"; }

.mdi-clock-out:before {
  content: "\F154"; }

.mdi-clock-start:before {
  content: "\F155"; }

.mdi-close:before {
  content: "\F156"; }

.mdi-close-box:before {
  content: "\F157"; }

.mdi-close-box-outline:before {
  content: "\F158"; }

.mdi-close-circle:before {
  content: "\F159"; }

.mdi-close-circle-outline:before {
  content: "\F15A"; }

.mdi-close-network:before {
  content: "\F15B"; }

.mdi-close-octagon:before {
  content: "\F15C"; }

.mdi-close-octagon-outline:before {
  content: "\F15D"; }

.mdi-close-outline:before {
  content: "\F6C8"; }

.mdi-closed-caption:before {
  content: "\F15E"; }

.mdi-cloud:before {
  content: "\F15F"; }

.mdi-cloud-braces:before {
  content: "\F7B4"; }

.mdi-cloud-check:before {
  content: "\F160"; }

.mdi-cloud-circle:before {
  content: "\F161"; }

.mdi-cloud-download:before {
  content: "\F162"; }

.mdi-cloud-off-outline:before {
  content: "\F164"; }

.mdi-cloud-outline:before {
  content: "\F163"; }

.mdi-cloud-print:before {
  content: "\F165"; }

.mdi-cloud-print-outline:before {
  content: "\F166"; }

.mdi-cloud-sync:before {
  content: "\F63F"; }

.mdi-cloud-tags:before {
  content: "\F7B5"; }

.mdi-cloud-upload:before {
  content: "\F167"; }

.mdi-clover:before {
  content: "\F815"; }

.mdi-code-array:before {
  content: "\F168"; }

.mdi-code-braces:before {
  content: "\F169"; }

.mdi-code-brackets:before {
  content: "\F16A"; }

.mdi-code-equal:before {
  content: "\F16B"; }

.mdi-code-greater-than:before {
  content: "\F16C"; }

.mdi-code-greater-than-or-equal:before {
  content: "\F16D"; }

.mdi-code-less-than:before {
  content: "\F16E"; }

.mdi-code-less-than-or-equal:before {
  content: "\F16F"; }

.mdi-code-not-equal:before {
  content: "\F170"; }

.mdi-code-not-equal-variant:before {
  content: "\F171"; }

.mdi-code-parentheses:before {
  content: "\F172"; }

.mdi-code-string:before {
  content: "\F173"; }

.mdi-code-tags:before {
  content: "\F174"; }

.mdi-code-tags-check:before {
  content: "\F693"; }

.mdi-codepen:before {
  content: "\F175"; }

.mdi-coffee:before {
  content: "\F176"; }

.mdi-coffee-outline:before {
  content: "\F6C9"; }

.mdi-coffee-to-go:before {
  content: "\F177"; }

.mdi-cogs:before {
  content: "\F8D5"; }

.mdi-coin:before {
  content: "\F178"; }

.mdi-coins:before {
  content: "\F694"; }

.mdi-collage:before {
  content: "\F640"; }

.mdi-color-helper:before {
  content: "\F179"; }

.mdi-comment:before {
  content: "\F17A"; }

.mdi-comment-account:before {
  content: "\F17B"; }

.mdi-comment-account-outline:before {
  content: "\F17C"; }

.mdi-comment-alert:before {
  content: "\F17D"; }

.mdi-comment-alert-outline:before {
  content: "\F17E"; }

.mdi-comment-check:before {
  content: "\F17F"; }

.mdi-comment-check-outline:before {
  content: "\F180"; }

.mdi-comment-multiple:before {
  content: "\F85E"; }

.mdi-comment-multiple-outline:before {
  content: "\F181"; }

.mdi-comment-outline:before {
  content: "\F182"; }

.mdi-comment-plus-outline:before {
  content: "\F183"; }

.mdi-comment-processing:before {
  content: "\F184"; }

.mdi-comment-processing-outline:before {
  content: "\F185"; }

.mdi-comment-question:before {
  content: "\F816"; }

.mdi-comment-question-outline:before {
  content: "\F186"; }

.mdi-comment-remove:before {
  content: "\F5DE"; }

.mdi-comment-remove-outline:before {
  content: "\F187"; }

.mdi-comment-text:before {
  content: "\F188"; }

.mdi-comment-text-multiple:before {
  content: "\F85F"; }

.mdi-comment-text-multiple-outline:before {
  content: "\F860"; }

.mdi-comment-text-outline:before {
  content: "\F189"; }

.mdi-compare:before {
  content: "\F18A"; }

.mdi-compass:before {
  content: "\F18B"; }

.mdi-compass-outline:before {
  content: "\F18C"; }

.mdi-console:before {
  content: "\F18D"; }

.mdi-console-line:before {
  content: "\F7B6"; }

.mdi-console-network:before {
  content: "\F8A8"; }

.mdi-contact-mail:before {
  content: "\F18E"; }

.mdi-contacts:before {
  content: "\F6CA"; }

.mdi-content-copy:before {
  content: "\F18F"; }

.mdi-content-cut:before {
  content: "\F190"; }

.mdi-content-duplicate:before {
  content: "\F191"; }

.mdi-content-paste:before {
  content: "\F192"; }

.mdi-content-save:before {
  content: "\F193"; }

.mdi-content-save-all:before {
  content: "\F194"; }

.mdi-content-save-outline:before {
  content: "\F817"; }

.mdi-content-save-settings:before {
  content: "\F61B"; }

.mdi-contrast:before {
  content: "\F195"; }

.mdi-contrast-box:before {
  content: "\F196"; }

.mdi-contrast-circle:before {
  content: "\F197"; }

.mdi-cookie:before {
  content: "\F198"; }

.mdi-copyright:before {
  content: "\F5E6"; }

.mdi-corn:before {
  content: "\F7B7"; }

.mdi-counter:before {
  content: "\F199"; }

.mdi-cow:before {
  content: "\F19A"; }

.mdi-crane:before {
  content: "\F861"; }

.mdi-creation:before {
  content: "\F1C9"; }

.mdi-credit-card:before {
  content: "\F19B"; }

.mdi-credit-card-multiple:before {
  content: "\F19C"; }

.mdi-credit-card-off:before {
  content: "\F5E4"; }

.mdi-credit-card-plus:before {
  content: "\F675"; }

.mdi-credit-card-scan:before {
  content: "\F19D"; }

.mdi-credit-card-settings:before {
  content: "\F8D6"; }

.mdi-crop:before {
  content: "\F19E"; }

.mdi-crop-free:before {
  content: "\F19F"; }

.mdi-crop-landscape:before {
  content: "\F1A0"; }

.mdi-crop-portrait:before {
  content: "\F1A1"; }

.mdi-crop-rotate:before {
  content: "\F695"; }

.mdi-crop-square:before {
  content: "\F1A2"; }

.mdi-crosshairs:before {
  content: "\F1A3"; }

.mdi-crosshairs-gps:before {
  content: "\F1A4"; }

.mdi-crown:before {
  content: "\F1A5"; }

.mdi-cube:before {
  content: "\F1A6"; }

.mdi-cube-outline:before {
  content: "\F1A7"; }

.mdi-cube-send:before {
  content: "\F1A8"; }

.mdi-cube-unfolded:before {
  content: "\F1A9"; }

.mdi-cup:before {
  content: "\F1AA"; }

.mdi-cup-off:before {
  content: "\F5E5"; }

.mdi-cup-water:before {
  content: "\F1AB"; }

.mdi-curling:before {
  content: "\F862"; }

.mdi-currency-bdt:before {
  content: "\F863"; }

.mdi-currency-btc:before {
  content: "\F1AC"; }

.mdi-currency-chf:before {
  content: "\F7B8"; }

.mdi-currency-cny:before {
  content: "\F7B9"; }

.mdi-currency-eth:before {
  content: "\F7BA"; }

.mdi-currency-eur:before {
  content: "\F1AD"; }

.mdi-currency-gbp:before {
  content: "\F1AE"; }

.mdi-currency-inr:before {
  content: "\F1AF"; }

.mdi-currency-jpy:before {
  content: "\F7BB"; }

.mdi-currency-krw:before {
  content: "\F7BC"; }

.mdi-currency-kzt:before {
  content: "\F864"; }

.mdi-currency-ngn:before {
  content: "\F1B0"; }

.mdi-currency-rub:before {
  content: "\F1B1"; }

.mdi-currency-sign:before {
  content: "\F7BD"; }

.mdi-currency-try:before {
  content: "\F1B2"; }

.mdi-currency-twd:before {
  content: "\F7BE"; }

.mdi-currency-usd:before {
  content: "\F1B3"; }

.mdi-currency-usd-off:before {
  content: "\F679"; }

.mdi-cursor-default:before {
  content: "\F1B4"; }

.mdi-cursor-default-outline:before {
  content: "\F1B5"; }

.mdi-cursor-move:before {
  content: "\F1B6"; }

.mdi-cursor-pointer:before {
  content: "\F1B7"; }

.mdi-cursor-text:before {
  content: "\F5E7"; }

.mdi-database:before {
  content: "\F1B8"; }

.mdi-database-minus:before {
  content: "\F1B9"; }

.mdi-database-plus:before {
  content: "\F1BA"; }

.mdi-database-search:before {
  content: "\F865"; }

.mdi-death-star:before {
  content: "\F8D7"; }

.mdi-death-star-variant:before {
  content: "\F8D8"; }

.mdi-debian:before {
  content: "\F8D9"; }

.mdi-debug-step-into:before {
  content: "\F1BB"; }

.mdi-debug-step-out:before {
  content: "\F1BC"; }

.mdi-debug-step-over:before {
  content: "\F1BD"; }

.mdi-decagram:before {
  content: "\F76B"; }

.mdi-decagram-outline:before {
  content: "\F76C"; }

.mdi-decimal-decrease:before {
  content: "\F1BE"; }

.mdi-decimal-increase:before {
  content: "\F1BF"; }

.mdi-delete:before {
  content: "\F1C0"; }

.mdi-delete-circle:before {
  content: "\F682"; }

.mdi-delete-empty:before {
  content: "\F6CB"; }

.mdi-delete-forever:before {
  content: "\F5E8"; }

.mdi-delete-restore:before {
  content: "\F818"; }

.mdi-delete-sweep:before {
  content: "\F5E9"; }

.mdi-delete-variant:before {
  content: "\F1C1"; }

.mdi-delta:before {
  content: "\F1C2"; }

.mdi-deskphone:before {
  content: "\F1C3"; }

.mdi-desktop-classic:before {
  content: "\F7BF"; }

.mdi-desktop-mac:before {
  content: "\F1C4"; }

.mdi-desktop-tower:before {
  content: "\F1C5"; }

.mdi-details:before {
  content: "\F1C6"; }

.mdi-developer-board:before {
  content: "\F696"; }

.mdi-deviantart:before {
  content: "\F1C7"; }

.mdi-dialpad:before {
  content: "\F61C"; }

.mdi-diamond:before {
  content: "\F1C8"; }

.mdi-dice-1:before {
  content: "\F1CA"; }

.mdi-dice-2:before {
  content: "\F1CB"; }

.mdi-dice-3:before {
  content: "\F1CC"; }

.mdi-dice-4:before {
  content: "\F1CD"; }

.mdi-dice-5:before {
  content: "\F1CE"; }

.mdi-dice-6:before {
  content: "\F1CF"; }

.mdi-dice-d10:before {
  content: "\F76E"; }

.mdi-dice-d12:before {
  content: "\F866"; }

.mdi-dice-d20:before {
  content: "\F5EA"; }

.mdi-dice-d4:before {
  content: "\F5EB"; }

.mdi-dice-d6:before {
  content: "\F5EC"; }

.mdi-dice-d8:before {
  content: "\F5ED"; }

.mdi-dice-multiple:before {
  content: "\F76D"; }

.mdi-dictionary:before {
  content: "\F61D"; }

.mdi-dip-switch:before {
  content: "\F7C0"; }

.mdi-directions:before {
  content: "\F1D0"; }

.mdi-directions-fork:before {
  content: "\F641"; }

.mdi-discord:before {
  content: "\F66F"; }

.mdi-disk:before {
  content: "\F5EE"; }

.mdi-disk-alert:before {
  content: "\F1D1"; }

.mdi-disqus:before {
  content: "\F1D2"; }

.mdi-disqus-outline:before {
  content: "\F1D3"; }

.mdi-division:before {
  content: "\F1D4"; }

.mdi-division-box:before {
  content: "\F1D5"; }

.mdi-dna:before {
  content: "\F683"; }

.mdi-dns:before {
  content: "\F1D6"; }

.mdi-do-not-disturb:before {
  content: "\F697"; }

.mdi-do-not-disturb-off:before {
  content: "\F698"; }

.mdi-docker:before {
  content: "\F867"; }

.mdi-dolby:before {
  content: "\F6B2"; }

.mdi-domain:before {
  content: "\F1D7"; }

.mdi-donkey:before {
  content: "\F7C1"; }

.mdi-door:before {
  content: "\F819"; }

.mdi-door-closed:before {
  content: "\F81A"; }

.mdi-door-open:before {
  content: "\F81B"; }

.mdi-doorbell-video:before {
  content: "\F868"; }

.mdi-dots-horizontal:before {
  content: "\F1D8"; }

.mdi-dots-horizontal-circle:before {
  content: "\F7C2"; }

.mdi-dots-vertical:before {
  content: "\F1D9"; }

.mdi-dots-vertical-circle:before {
  content: "\F7C3"; }

.mdi-douban:before {
  content: "\F699"; }

.mdi-download:before {
  content: "\F1DA"; }

.mdi-download-network:before {
  content: "\F6F3"; }

.mdi-drag:before {
  content: "\F1DB"; }

.mdi-drag-horizontal:before {
  content: "\F1DC"; }

.mdi-drag-vertical:before {
  content: "\F1DD"; }

.mdi-drawing:before {
  content: "\F1DE"; }

.mdi-drawing-box:before {
  content: "\F1DF"; }

.mdi-dribbble:before {
  content: "\F1E0"; }

.mdi-dribbble-box:before {
  content: "\F1E1"; }

.mdi-drone:before {
  content: "\F1E2"; }

.mdi-dropbox:before {
  content: "\F1E3"; }

.mdi-drupal:before {
  content: "\F1E4"; }

.mdi-duck:before {
  content: "\F1E5"; }

.mdi-dumbbell:before {
  content: "\F1E6"; }

.mdi-ear-hearing:before {
  content: "\F7C4"; }

.mdi-earth:before {
  content: "\F1E7"; }

.mdi-earth-box:before {
  content: "\F6CC"; }

.mdi-earth-box-off:before {
  content: "\F6CD"; }

.mdi-earth-off:before {
  content: "\F1E8"; }

.mdi-edge:before {
  content: "\F1E9"; }

.mdi-eject:before {
  content: "\F1EA"; }

.mdi-elephant:before {
  content: "\F7C5"; }

.mdi-elevation-decline:before {
  content: "\F1EB"; }

.mdi-elevation-rise:before {
  content: "\F1EC"; }

.mdi-elevator:before {
  content: "\F1ED"; }

.mdi-email:before {
  content: "\F1EE"; }

.mdi-email-alert:before {
  content: "\F6CE"; }

.mdi-email-open:before {
  content: "\F1EF"; }

.mdi-email-open-outline:before {
  content: "\F5EF"; }

.mdi-email-outline:before {
  content: "\F1F0"; }

.mdi-email-secure:before {
  content: "\F1F1"; }

.mdi-email-variant:before {
  content: "\F5F0"; }

.mdi-emby:before {
  content: "\F6B3"; }

.mdi-emoticon:before {
  content: "\F1F2"; }

.mdi-emoticon-cool:before {
  content: "\F1F3"; }

.mdi-emoticon-dead:before {
  content: "\F69A"; }

.mdi-emoticon-devil:before {
  content: "\F1F4"; }

.mdi-emoticon-excited:before {
  content: "\F69B"; }

.mdi-emoticon-happy:before {
  content: "\F1F5"; }

.mdi-emoticon-neutral:before {
  content: "\F1F6"; }

.mdi-emoticon-poop:before {
  content: "\F1F7"; }

.mdi-emoticon-sad:before {
  content: "\F1F8"; }

.mdi-emoticon-tongue:before {
  content: "\F1F9"; }

.mdi-engine:before {
  content: "\F1FA"; }

.mdi-engine-outline:before {
  content: "\F1FB"; }

.mdi-equal:before {
  content: "\F1FC"; }

.mdi-equal-box:before {
  content: "\F1FD"; }

.mdi-eraser:before {
  content: "\F1FE"; }

.mdi-eraser-variant:before {
  content: "\F642"; }

.mdi-escalator:before {
  content: "\F1FF"; }

.mdi-ethereum:before {
  content: "\F869"; }

.mdi-ethernet:before {
  content: "\F200"; }

.mdi-ethernet-cable:before {
  content: "\F201"; }

.mdi-ethernet-cable-off:before {
  content: "\F202"; }

.mdi-etsy:before {
  content: "\F203"; }

.mdi-ev-station:before {
  content: "\F5F1"; }

.mdi-eventbrite:before {
  content: "\F7C6"; }

.mdi-evernote:before {
  content: "\F204"; }

.mdi-exclamation:before {
  content: "\F205"; }

.mdi-exit-to-app:before {
  content: "\F206"; }

.mdi-export:before {
  content: "\F207"; }

.mdi-eye:before {
  content: "\F208"; }

.mdi-eye-off:before {
  content: "\F209"; }

.mdi-eye-off-outline:before {
  content: "\F6D0"; }

.mdi-eye-outline:before {
  content: "\F6CF"; }

.mdi-eye-plus:before {
  content: "\F86A"; }

.mdi-eye-plus-outline:before {
  content: "\F86B"; }

.mdi-eye-settings:before {
  content: "\F86C"; }

.mdi-eye-settings-outline:before {
  content: "\F86D"; }

.mdi-eyedropper:before {
  content: "\F20A"; }

.mdi-eyedropper-variant:before {
  content: "\F20B"; }

.mdi-face:before {
  content: "\F643"; }

.mdi-face-profile:before {
  content: "\F644"; }

.mdi-facebook:before {
  content: "\F20C"; }

.mdi-facebook-box:before {
  content: "\F20D"; }

.mdi-facebook-messenger:before {
  content: "\F20E"; }

.mdi-factory:before {
  content: "\F20F"; }

.mdi-fan:before {
  content: "\F210"; }

.mdi-fan-off:before {
  content: "\F81C"; }

.mdi-fast-forward:before {
  content: "\F211"; }

.mdi-fast-forward-outline:before {
  content: "\F6D1"; }

.mdi-fax:before {
  content: "\F212"; }

.mdi-feather:before {
  content: "\F6D2"; }

.mdi-fedora:before {
  content: "\F8DA"; }

.mdi-ferry:before {
  content: "\F213"; }

.mdi-file:before {
  content: "\F214"; }

.mdi-file-account:before {
  content: "\F73A"; }

.mdi-file-chart:before {
  content: "\F215"; }

.mdi-file-check:before {
  content: "\F216"; }

.mdi-file-cloud:before {
  content: "\F217"; }

.mdi-file-compare:before {
  content: "\F8A9"; }

.mdi-file-delimited:before {
  content: "\F218"; }

.mdi-file-document:before {
  content: "\F219"; }

.mdi-file-document-box:before {
  content: "\F21A"; }

.mdi-file-excel:before {
  content: "\F21B"; }

.mdi-file-excel-box:before {
  content: "\F21C"; }

.mdi-file-export:before {
  content: "\F21D"; }

.mdi-file-find:before {
  content: "\F21E"; }

.mdi-file-hidden:before {
  content: "\F613"; }

.mdi-file-image:before {
  content: "\F21F"; }

.mdi-file-import:before {
  content: "\F220"; }

.mdi-file-lock:before {
  content: "\F221"; }

.mdi-file-multiple:before {
  content: "\F222"; }

.mdi-file-music:before {
  content: "\F223"; }

.mdi-file-outline:before {
  content: "\F224"; }

.mdi-file-pdf:before {
  content: "\F225"; }

.mdi-file-pdf-box:before {
  content: "\F226"; }

.mdi-file-percent:before {
  content: "\F81D"; }

.mdi-file-plus:before {
  content: "\F751"; }

.mdi-file-powerpoint:before {
  content: "\F227"; }

.mdi-file-powerpoint-box:before {
  content: "\F228"; }

.mdi-file-presentation-box:before {
  content: "\F229"; }

.mdi-file-question:before {
  content: "\F86E"; }

.mdi-file-restore:before {
  content: "\F670"; }

.mdi-file-send:before {
  content: "\F22A"; }

.mdi-file-tree:before {
  content: "\F645"; }

.mdi-file-undo:before {
  content: "\F8DB"; }

.mdi-file-video:before {
  content: "\F22B"; }

.mdi-file-word:before {
  content: "\F22C"; }

.mdi-file-word-box:before {
  content: "\F22D"; }

.mdi-file-xml:before {
  content: "\F22E"; }

.mdi-film:before {
  content: "\F22F"; }

.mdi-filmstrip:before {
  content: "\F230"; }

.mdi-filmstrip-off:before {
  content: "\F231"; }

.mdi-filter:before {
  content: "\F232"; }

.mdi-filter-outline:before {
  content: "\F233"; }

.mdi-filter-remove:before {
  content: "\F234"; }

.mdi-filter-remove-outline:before {
  content: "\F235"; }

.mdi-filter-variant:before {
  content: "\F236"; }

.mdi-finance:before {
  content: "\F81E"; }

.mdi-find-replace:before {
  content: "\F6D3"; }

.mdi-fingerprint:before {
  content: "\F237"; }

.mdi-fire:before {
  content: "\F238"; }

.mdi-fire-truck:before {
  content: "\F8AA"; }

.mdi-firefox:before {
  content: "\F239"; }

.mdi-fish:before {
  content: "\F23A"; }

.mdi-flag:before {
  content: "\F23B"; }

.mdi-flag-checkered:before {
  content: "\F23C"; }

.mdi-flag-outline:before {
  content: "\F23D"; }

.mdi-flag-triangle:before {
  content: "\F23F"; }

.mdi-flag-variant:before {
  content: "\F240"; }

.mdi-flag-variant-outline:before {
  content: "\F23E"; }

.mdi-flash:before {
  content: "\F241"; }

.mdi-flash-auto:before {
  content: "\F242"; }

.mdi-flash-circle:before {
  content: "\F81F"; }

.mdi-flash-off:before {
  content: "\F243"; }

.mdi-flash-outline:before {
  content: "\F6D4"; }

.mdi-flash-red-eye:before {
  content: "\F67A"; }

.mdi-flashlight:before {
  content: "\F244"; }

.mdi-flashlight-off:before {
  content: "\F245"; }

.mdi-flask:before {
  content: "\F093"; }

.mdi-flask-empty:before {
  content: "\F094"; }

.mdi-flask-empty-outline:before {
  content: "\F095"; }

.mdi-flask-outline:before {
  content: "\F096"; }

.mdi-flattr:before {
  content: "\F246"; }

.mdi-flip-to-back:before {
  content: "\F247"; }

.mdi-flip-to-front:before {
  content: "\F248"; }

.mdi-floor-lamp:before {
  content: "\F8DC"; }

.mdi-floor-plan:before {
  content: "\F820"; }

.mdi-floppy:before {
  content: "\F249"; }

.mdi-flower:before {
  content: "\F24A"; }

.mdi-folder:before {
  content: "\F24B"; }

.mdi-folder-account:before {
  content: "\F24C"; }

.mdi-folder-download:before {
  content: "\F24D"; }

.mdi-folder-edit:before {
  content: "\F8DD"; }

.mdi-folder-google-drive:before {
  content: "\F24E"; }

.mdi-folder-image:before {
  content: "\F24F"; }

.mdi-folder-key:before {
  content: "\F8AB"; }

.mdi-folder-key-network:before {
  content: "\F8AC"; }

.mdi-folder-lock:before {
  content: "\F250"; }

.mdi-folder-lock-open:before {
  content: "\F251"; }

.mdi-folder-move:before {
  content: "\F252"; }

.mdi-folder-multiple:before {
  content: "\F253"; }

.mdi-folder-multiple-image:before {
  content: "\F254"; }

.mdi-folder-multiple-outline:before {
  content: "\F255"; }

.mdi-folder-network:before {
  content: "\F86F"; }

.mdi-folder-open:before {
  content: "\F76F"; }

.mdi-folder-outline:before {
  content: "\F256"; }

.mdi-folder-plus:before {
  content: "\F257"; }

.mdi-folder-remove:before {
  content: "\F258"; }

.mdi-folder-star:before {
  content: "\F69C"; }

.mdi-folder-upload:before {
  content: "\F259"; }

.mdi-font-awesome:before {
  content: "\F03A"; }

.mdi-food:before {
  content: "\F25A"; }

.mdi-food-apple:before {
  content: "\F25B"; }

.mdi-food-croissant:before {
  content: "\F7C7"; }

.mdi-food-fork-drink:before {
  content: "\F5F2"; }

.mdi-food-off:before {
  content: "\F5F3"; }

.mdi-food-variant:before {
  content: "\F25C"; }

.mdi-football:before {
  content: "\F25D"; }

.mdi-football-australian:before {
  content: "\F25E"; }

.mdi-football-helmet:before {
  content: "\F25F"; }

.mdi-forklift:before {
  content: "\F7C8"; }

.mdi-format-align-bottom:before {
  content: "\F752"; }

.mdi-format-align-center:before {
  content: "\F260"; }

.mdi-format-align-justify:before {
  content: "\F261"; }

.mdi-format-align-left:before {
  content: "\F262"; }

.mdi-format-align-middle:before {
  content: "\F753"; }

.mdi-format-align-right:before {
  content: "\F263"; }

.mdi-format-align-top:before {
  content: "\F754"; }

.mdi-format-annotation-plus:before {
  content: "\F646"; }

.mdi-format-bold:before {
  content: "\F264"; }

.mdi-format-clear:before {
  content: "\F265"; }

.mdi-format-color-fill:before {
  content: "\F266"; }

.mdi-format-color-text:before {
  content: "\F69D"; }

.mdi-format-columns:before {
  content: "\F8DE"; }

.mdi-format-float-center:before {
  content: "\F267"; }

.mdi-format-float-left:before {
  content: "\F268"; }

.mdi-format-float-none:before {
  content: "\F269"; }

.mdi-format-float-right:before {
  content: "\F26A"; }

.mdi-format-font:before {
  content: "\F6D5"; }

.mdi-format-header-1:before {
  content: "\F26B"; }

.mdi-format-header-2:before {
  content: "\F26C"; }

.mdi-format-header-3:before {
  content: "\F26D"; }

.mdi-format-header-4:before {
  content: "\F26E"; }

.mdi-format-header-5:before {
  content: "\F26F"; }

.mdi-format-header-6:before {
  content: "\F270"; }

.mdi-format-header-decrease:before {
  content: "\F271"; }

.mdi-format-header-equal:before {
  content: "\F272"; }

.mdi-format-header-increase:before {
  content: "\F273"; }

.mdi-format-header-pound:before {
  content: "\F274"; }

.mdi-format-horizontal-align-center:before {
  content: "\F61E"; }

.mdi-format-horizontal-align-left:before {
  content: "\F61F"; }

.mdi-format-horizontal-align-right:before {
  content: "\F620"; }

.mdi-format-indent-decrease:before {
  content: "\F275"; }

.mdi-format-indent-increase:before {
  content: "\F276"; }

.mdi-format-italic:before {
  content: "\F277"; }

.mdi-format-line-spacing:before {
  content: "\F278"; }

.mdi-format-line-style:before {
  content: "\F5C8"; }

.mdi-format-line-weight:before {
  content: "\F5C9"; }

.mdi-format-list-bulleted:before {
  content: "\F279"; }

.mdi-format-list-bulleted-type:before {
  content: "\F27A"; }

.mdi-format-list-checks:before {
  content: "\F755"; }

.mdi-format-list-numbers:before {
  content: "\F27B"; }

.mdi-format-page-break:before {
  content: "\F6D6"; }

.mdi-format-paint:before {
  content: "\F27C"; }

.mdi-format-paragraph:before {
  content: "\F27D"; }

.mdi-format-pilcrow:before {
  content: "\F6D7"; }

.mdi-format-quote-close:before {
  content: "\F27E"; }

.mdi-format-quote-open:before {
  content: "\F756"; }

.mdi-format-rotate-90:before {
  content: "\F6A9"; }

.mdi-format-section:before {
  content: "\F69E"; }

.mdi-format-size:before {
  content: "\F27F"; }

.mdi-format-strikethrough:before {
  content: "\F280"; }

.mdi-format-strikethrough-variant:before {
  content: "\F281"; }

.mdi-format-subscript:before {
  content: "\F282"; }

.mdi-format-superscript:before {
  content: "\F283"; }

.mdi-format-text:before {
  content: "\F284"; }

.mdi-format-textdirection-l-to-r:before {
  content: "\F285"; }

.mdi-format-textdirection-r-to-l:before {
  content: "\F286"; }

.mdi-format-title:before {
  content: "\F5F4"; }

.mdi-format-underline:before {
  content: "\F287"; }

.mdi-format-vertical-align-bottom:before {
  content: "\F621"; }

.mdi-format-vertical-align-center:before {
  content: "\F622"; }

.mdi-format-vertical-align-top:before {
  content: "\F623"; }

.mdi-format-wrap-inline:before {
  content: "\F288"; }

.mdi-format-wrap-square:before {
  content: "\F289"; }

.mdi-format-wrap-tight:before {
  content: "\F28A"; }

.mdi-format-wrap-top-bottom:before {
  content: "\F28B"; }

.mdi-forum:before {
  content: "\F28C"; }

.mdi-forum-outline:before {
  content: "\F821"; }

.mdi-forward:before {
  content: "\F28D"; }

.mdi-foursquare:before {
  content: "\F28E"; }

.mdi-freebsd:before {
  content: "\F8DF"; }

.mdi-fridge:before {
  content: "\F28F"; }

.mdi-fridge-filled:before {
  content: "\F290"; }

.mdi-fridge-filled-bottom:before {
  content: "\F291"; }

.mdi-fridge-filled-top:before {
  content: "\F292"; }

.mdi-fuel:before {
  content: "\F7C9"; }

.mdi-fullscreen:before {
  content: "\F293"; }

.mdi-fullscreen-exit:before {
  content: "\F294"; }

.mdi-function:before {
  content: "\F295"; }

.mdi-function-variant:before {
  content: "\F870"; }

.mdi-gamepad:before {
  content: "\F296"; }

.mdi-gamepad-variant:before {
  content: "\F297"; }

.mdi-garage:before {
  content: "\F6D8"; }

.mdi-garage-alert:before {
  content: "\F871"; }

.mdi-garage-open:before {
  content: "\F6D9"; }

.mdi-gas-cylinder:before {
  content: "\F647"; }

.mdi-gas-station:before {
  content: "\F298"; }

.mdi-gate:before {
  content: "\F299"; }

.mdi-gate-and:before {
  content: "\F8E0"; }

.mdi-gate-nand:before {
  content: "\F8E1"; }

.mdi-gate-nor:before {
  content: "\F8E2"; }

.mdi-gate-not:before {
  content: "\F8E3"; }

.mdi-gate-or:before {
  content: "\F8E4"; }

.mdi-gate-xnor:before {
  content: "\F8E5"; }

.mdi-gate-xor:before {
  content: "\F8E6"; }

.mdi-gauge:before {
  content: "\F29A"; }

.mdi-gauge-empty:before {
  content: "\F872"; }

.mdi-gauge-full:before {
  content: "\F873"; }

.mdi-gauge-low:before {
  content: "\F874"; }

.mdi-gavel:before {
  content: "\F29B"; }

.mdi-gender-female:before {
  content: "\F29C"; }

.mdi-gender-male:before {
  content: "\F29D"; }

.mdi-gender-male-female:before {
  content: "\F29E"; }

.mdi-gender-transgender:before {
  content: "\F29F"; }

.mdi-gentoo:before {
  content: "\F8E7"; }

.mdi-gesture:before {
  content: "\F7CA"; }

.mdi-gesture-double-tap:before {
  content: "\F73B"; }

.mdi-gesture-swipe-down:before {
  content: "\F73C"; }

.mdi-gesture-swipe-left:before {
  content: "\F73D"; }

.mdi-gesture-swipe-right:before {
  content: "\F73E"; }

.mdi-gesture-swipe-up:before {
  content: "\F73F"; }

.mdi-gesture-tap:before {
  content: "\F740"; }

.mdi-gesture-two-double-tap:before {
  content: "\F741"; }

.mdi-gesture-two-tap:before {
  content: "\F742"; }

.mdi-ghost:before {
  content: "\F2A0"; }

.mdi-gift:before {
  content: "\F2A1"; }

.mdi-git:before {
  content: "\F2A2"; }

.mdi-github-box:before {
  content: "\F2A3"; }

.mdi-github-circle:before {
  content: "\F2A4"; }

.mdi-github-face:before {
  content: "\F6DA"; }

.mdi-glass-cocktail:before {
  content: "\F356"; }

.mdi-glass-flute:before {
  content: "\F2A5"; }

.mdi-glass-mug:before {
  content: "\F2A6"; }

.mdi-glass-stange:before {
  content: "\F2A7"; }

.mdi-glass-tulip:before {
  content: "\F2A8"; }

.mdi-glass-wine:before {
  content: "\F875"; }

.mdi-glassdoor:before {
  content: "\F2A9"; }

.mdi-glasses:before {
  content: "\F2AA"; }

.mdi-globe-model:before {
  content: "\F8E8"; }

.mdi-gmail:before {
  content: "\F2AB"; }

.mdi-gnome:before {
  content: "\F2AC"; }

.mdi-golf:before {
  content: "\F822"; }

.mdi-gondola:before {
  content: "\F685"; }

.mdi-google:before {
  content: "\F2AD"; }

.mdi-google-allo:before {
  content: "\F801"; }

.mdi-google-analytics:before {
  content: "\F7CB"; }

.mdi-google-assistant:before {
  content: "\F7CC"; }

.mdi-google-cardboard:before {
  content: "\F2AE"; }

.mdi-google-chrome:before {
  content: "\F2AF"; }

.mdi-google-circles:before {
  content: "\F2B0"; }

.mdi-google-circles-communities:before {
  content: "\F2B1"; }

.mdi-google-circles-extended:before {
  content: "\F2B2"; }

.mdi-google-circles-group:before {
  content: "\F2B3"; }

.mdi-google-controller:before {
  content: "\F2B4"; }

.mdi-google-controller-off:before {
  content: "\F2B5"; }

.mdi-google-drive:before {
  content: "\F2B6"; }

.mdi-google-earth:before {
  content: "\F2B7"; }

.mdi-google-glass:before {
  content: "\F2B8"; }

.mdi-google-hangouts:before {
  content: "\F2C9"; }

.mdi-google-home:before {
  content: "\F823"; }

.mdi-google-keep:before {
  content: "\F6DB"; }

.mdi-google-maps:before {
  content: "\F5F5"; }

.mdi-google-nearby:before {
  content: "\F2B9"; }

.mdi-google-pages:before {
  content: "\F2BA"; }

.mdi-google-photos:before {
  content: "\F6DC"; }

.mdi-google-physical-web:before {
  content: "\F2BB"; }

.mdi-google-play:before {
  content: "\F2BC"; }

.mdi-google-plus:before {
  content: "\F2BD"; }

.mdi-google-plus-box:before {
  content: "\F2BE"; }

.mdi-google-translate:before {
  content: "\F2BF"; }

.mdi-google-wallet:before {
  content: "\F2C0"; }

.mdi-gpu:before {
  content: "\F8AD"; }

.mdi-gradient:before {
  content: "\F69F"; }

.mdi-graphql:before {
  content: "\F876"; }

.mdi-grease-pencil:before {
  content: "\F648"; }

.mdi-grid:before {
  content: "\F2C1"; }

.mdi-grid-large:before {
  content: "\F757"; }

.mdi-grid-off:before {
  content: "\F2C2"; }

.mdi-group:before {
  content: "\F2C3"; }

.mdi-guitar-acoustic:before {
  content: "\F770"; }

.mdi-guitar-electric:before {
  content: "\F2C4"; }

.mdi-guitar-pick:before {
  content: "\F2C5"; }

.mdi-guitar-pick-outline:before {
  content: "\F2C6"; }

.mdi-guy-fawkes-mask:before {
  content: "\F824"; }

.mdi-hackernews:before {
  content: "\F624"; }

.mdi-hamburger:before {
  content: "\F684"; }

.mdi-hammer:before {
  content: "\F8E9"; }

.mdi-hand-pointing-right:before {
  content: "\F2C7"; }

.mdi-hanger:before {
  content: "\F2C8"; }

.mdi-harddisk:before {
  content: "\F2CA"; }

.mdi-headphones:before {
  content: "\F2CB"; }

.mdi-headphones-box:before {
  content: "\F2CC"; }

.mdi-headphones-off:before {
  content: "\F7CD"; }

.mdi-headphones-settings:before {
  content: "\F2CD"; }

.mdi-headset:before {
  content: "\F2CE"; }

.mdi-headset-dock:before {
  content: "\F2CF"; }

.mdi-headset-off:before {
  content: "\F2D0"; }

.mdi-heart:before {
  content: "\F2D1"; }

.mdi-heart-box:before {
  content: "\F2D2"; }

.mdi-heart-box-outline:before {
  content: "\F2D3"; }

.mdi-heart-broken:before {
  content: "\F2D4"; }

.mdi-heart-half:before {
  content: "\F6DE"; }

.mdi-heart-half-full:before {
  content: "\F6DD"; }

.mdi-heart-half-outline:before {
  content: "\F6DF"; }

.mdi-heart-off:before {
  content: "\F758"; }

.mdi-heart-outline:before {
  content: "\F2D5"; }

.mdi-heart-pulse:before {
  content: "\F5F6"; }

.mdi-help:before {
  content: "\F2D6"; }

.mdi-help-box:before {
  content: "\F78A"; }

.mdi-help-circle:before {
  content: "\F2D7"; }

.mdi-help-circle-outline:before {
  content: "\F625"; }

.mdi-help-network:before {
  content: "\F6F4"; }

.mdi-hexagon:before {
  content: "\F2D8"; }

.mdi-hexagon-multiple:before {
  content: "\F6E0"; }

.mdi-hexagon-outline:before {
  content: "\F2D9"; }

.mdi-high-definition:before {
  content: "\F7CE"; }

.mdi-high-definition-box:before {
  content: "\F877"; }

.mdi-highway:before {
  content: "\F5F7"; }

.mdi-history:before {
  content: "\F2DA"; }

.mdi-hockey-puck:before {
  content: "\F878"; }

.mdi-hockey-sticks:before {
  content: "\F879"; }

.mdi-hololens:before {
  content: "\F2DB"; }

.mdi-home:before {
  content: "\F2DC"; }

.mdi-home-account:before {
  content: "\F825"; }

.mdi-home-alert:before {
  content: "\F87A"; }

.mdi-home-assistant:before {
  content: "\F7CF"; }

.mdi-home-automation:before {
  content: "\F7D0"; }

.mdi-home-circle:before {
  content: "\F7D1"; }

.mdi-home-currency-usd:before {
  content: "\F8AE"; }

.mdi-home-heart:before {
  content: "\F826"; }

.mdi-home-lock:before {
  content: "\F8EA"; }

.mdi-home-lock-open:before {
  content: "\F8EB"; }

.mdi-home-map-marker:before {
  content: "\F5F8"; }

.mdi-home-modern:before {
  content: "\F2DD"; }

.mdi-home-outline:before {
  content: "\F6A0"; }

.mdi-home-variant:before {
  content: "\F2DE"; }

.mdi-hook:before {
  content: "\F6E1"; }

.mdi-hook-off:before {
  content: "\F6E2"; }

.mdi-hops:before {
  content: "\F2DF"; }

.mdi-hospital:before {
  content: "\F2E0"; }

.mdi-hospital-building:before {
  content: "\F2E1"; }

.mdi-hospital-marker:before {
  content: "\F2E2"; }

.mdi-hot-tub:before {
  content: "\F827"; }

.mdi-hotel:before {
  content: "\F2E3"; }

.mdi-houzz:before {
  content: "\F2E4"; }

.mdi-houzz-box:before {
  content: "\F2E5"; }

.mdi-hulu:before {
  content: "\F828"; }

.mdi-human:before {
  content: "\F2E6"; }

.mdi-human-child:before {
  content: "\F2E7"; }

.mdi-human-female:before {
  content: "\F649"; }

.mdi-human-greeting:before {
  content: "\F64A"; }

.mdi-human-handsdown:before {
  content: "\F64B"; }

.mdi-human-handsup:before {
  content: "\F64C"; }

.mdi-human-male:before {
  content: "\F64D"; }

.mdi-human-male-female:before {
  content: "\F2E8"; }

.mdi-human-pregnant:before {
  content: "\F5CF"; }

.mdi-humble-bundle:before {
  content: "\F743"; }

.mdi-ice-cream:before {
  content: "\F829"; }

.mdi-image:before {
  content: "\F2E9"; }

.mdi-image-album:before {
  content: "\F2EA"; }

.mdi-image-area:before {
  content: "\F2EB"; }

.mdi-image-area-close:before {
  content: "\F2EC"; }

.mdi-image-broken:before {
  content: "\F2ED"; }

.mdi-image-broken-variant:before {
  content: "\F2EE"; }

.mdi-image-filter:before {
  content: "\F2EF"; }

.mdi-image-filter-black-white:before {
  content: "\F2F0"; }

.mdi-image-filter-center-focus:before {
  content: "\F2F1"; }

.mdi-image-filter-center-focus-weak:before {
  content: "\F2F2"; }

.mdi-image-filter-drama:before {
  content: "\F2F3"; }

.mdi-image-filter-frames:before {
  content: "\F2F4"; }

.mdi-image-filter-hdr:before {
  content: "\F2F5"; }

.mdi-image-filter-none:before {
  content: "\F2F6"; }

.mdi-image-filter-tilt-shift:before {
  content: "\F2F7"; }

.mdi-image-filter-vintage:before {
  content: "\F2F8"; }

.mdi-image-multiple:before {
  content: "\F2F9"; }

.mdi-image-off:before {
  content: "\F82A"; }

.mdi-image-plus:before {
  content: "\F87B"; }

.mdi-import:before {
  content: "\F2FA"; }

.mdi-inbox:before {
  content: "\F686"; }

.mdi-inbox-arrow-down:before {
  content: "\F2FB"; }

.mdi-inbox-arrow-up:before {
  content: "\F3D1"; }

.mdi-inbox-multiple:before {
  content: "\F8AF"; }

.mdi-incognito:before {
  content: "\F5F9"; }

.mdi-infinity:before {
  content: "\F6E3"; }

.mdi-information:before {
  content: "\F2FC"; }

.mdi-information-outline:before {
  content: "\F2FD"; }

.mdi-information-variant:before {
  content: "\F64E"; }

.mdi-instagram:before {
  content: "\F2FE"; }

.mdi-instapaper:before {
  content: "\F2FF"; }

.mdi-internet-explorer:before {
  content: "\F300"; }

.mdi-invert-colors:before {
  content: "\F301"; }

.mdi-itunes:before {
  content: "\F676"; }

.mdi-jeepney:before {
  content: "\F302"; }

.mdi-jira:before {
  content: "\F303"; }

.mdi-jquery:before {
  content: "\F87C"; }

.mdi-jsfiddle:before {
  content: "\F304"; }

.mdi-json:before {
  content: "\F626"; }

.mdi-karate:before {
  content: "\F82B"; }

.mdi-keg:before {
  content: "\F305"; }

.mdi-kettle:before {
  content: "\F5FA"; }

.mdi-key:before {
  content: "\F306"; }

.mdi-key-change:before {
  content: "\F307"; }

.mdi-key-minus:before {
  content: "\F308"; }

.mdi-key-plus:before {
  content: "\F309"; }

.mdi-key-remove:before {
  content: "\F30A"; }

.mdi-key-variant:before {
  content: "\F30B"; }

.mdi-keyboard:before {
  content: "\F30C"; }

.mdi-keyboard-backspace:before {
  content: "\F30D"; }

.mdi-keyboard-caps:before {
  content: "\F30E"; }

.mdi-keyboard-close:before {
  content: "\F30F"; }

.mdi-keyboard-off:before {
  content: "\F310"; }

.mdi-keyboard-return:before {
  content: "\F311"; }

.mdi-keyboard-tab:before {
  content: "\F312"; }

.mdi-keyboard-variant:before {
  content: "\F313"; }

.mdi-kickstarter:before {
  content: "\F744"; }

.mdi-kodi:before {
  content: "\F314"; }

.mdi-label:before {
  content: "\F315"; }

.mdi-label-outline:before {
  content: "\F316"; }

.mdi-ladybug:before {
  content: "\F82C"; }

.mdi-lambda:before {
  content: "\F627"; }

.mdi-lamp:before {
  content: "\F6B4"; }

.mdi-lan:before {
  content: "\F317"; }

.mdi-lan-connect:before {
  content: "\F318"; }

.mdi-lan-disconnect:before {
  content: "\F319"; }

.mdi-lan-pending:before {
  content: "\F31A"; }

.mdi-language-c:before {
  content: "\F671"; }

.mdi-language-cpp:before {
  content: "\F672"; }

.mdi-language-csharp:before {
  content: "\F31B"; }

.mdi-language-css3:before {
  content: "\F31C"; }

.mdi-language-go:before {
  content: "\F7D2"; }

.mdi-language-html5:before {
  content: "\F31D"; }

.mdi-language-javascript:before {
  content: "\F31E"; }

.mdi-language-lua:before {
  content: "\F8B0"; }

.mdi-language-php:before {
  content: "\F31F"; }

.mdi-language-python:before {
  content: "\F320"; }

.mdi-language-python-text:before {
  content: "\F321"; }

.mdi-language-r:before {
  content: "\F7D3"; }

.mdi-language-swift:before {
  content: "\F6E4"; }

.mdi-language-typescript:before {
  content: "\F6E5"; }

.mdi-laptop:before {
  content: "\F322"; }

.mdi-laptop-chromebook:before {
  content: "\F323"; }

.mdi-laptop-mac:before {
  content: "\F324"; }

.mdi-laptop-off:before {
  content: "\F6E6"; }

.mdi-laptop-windows:before {
  content: "\F325"; }

.mdi-lastfm:before {
  content: "\F326"; }

.mdi-lastpass:before {
  content: "\F446"; }

.mdi-launch:before {
  content: "\F327"; }

.mdi-lava-lamp:before {
  content: "\F7D4"; }

.mdi-layers:before {
  content: "\F328"; }

.mdi-layers-off:before {
  content: "\F329"; }

.mdi-lead-pencil:before {
  content: "\F64F"; }

.mdi-leaf:before {
  content: "\F32A"; }

.mdi-led-off:before {
  content: "\F32B"; }

.mdi-led-on:before {
  content: "\F32C"; }

.mdi-led-outline:before {
  content: "\F32D"; }

.mdi-led-strip:before {
  content: "\F7D5"; }

.mdi-led-variant-off:before {
  content: "\F32E"; }

.mdi-led-variant-on:before {
  content: "\F32F"; }

.mdi-led-variant-outline:before {
  content: "\F330"; }

.mdi-library:before {
  content: "\F331"; }

.mdi-library-books:before {
  content: "\F332"; }

.mdi-library-music:before {
  content: "\F333"; }

.mdi-library-plus:before {
  content: "\F334"; }

.mdi-lifebuoy:before {
  content: "\F87D"; }

.mdi-lightbulb:before {
  content: "\F335"; }

.mdi-lightbulb-on:before {
  content: "\F6E7"; }

.mdi-lightbulb-on-outline:before {
  content: "\F6E8"; }

.mdi-lightbulb-outline:before {
  content: "\F336"; }

.mdi-link:before {
  content: "\F337"; }

.mdi-link-off:before {
  content: "\F338"; }

.mdi-link-variant:before {
  content: "\F339"; }

.mdi-link-variant-off:before {
  content: "\F33A"; }

.mdi-linkedin:before {
  content: "\F33B"; }

.mdi-linkedin-box:before {
  content: "\F33C"; }

.mdi-linux:before {
  content: "\F33D"; }

.mdi-linux-mint:before {
  content: "\F8EC"; }

.mdi-loading:before {
  content: "\F771"; }

.mdi-lock:before {
  content: "\F33E"; }

.mdi-lock-alert:before {
  content: "\F8ED"; }

.mdi-lock-open:before {
  content: "\F33F"; }

.mdi-lock-open-outline:before {
  content: "\F340"; }

.mdi-lock-outline:before {
  content: "\F341"; }

.mdi-lock-pattern:before {
  content: "\F6E9"; }

.mdi-lock-plus:before {
  content: "\F5FB"; }

.mdi-lock-question:before {
  content: "\F8EE"; }

.mdi-lock-reset:before {
  content: "\F772"; }

.mdi-lock-smart:before {
  content: "\F8B1"; }

.mdi-locker:before {
  content: "\F7D6"; }

.mdi-locker-multiple:before {
  content: "\F7D7"; }

.mdi-login:before {
  content: "\F342"; }

.mdi-login-variant:before {
  content: "\F5FC"; }

.mdi-logout:before {
  content: "\F343"; }

.mdi-logout-variant:before {
  content: "\F5FD"; }

.mdi-looks:before {
  content: "\F344"; }

.mdi-loop:before {
  content: "\F6EA"; }

.mdi-loupe:before {
  content: "\F345"; }

.mdi-lumx:before {
  content: "\F346"; }

.mdi-magnet:before {
  content: "\F347"; }

.mdi-magnet-on:before {
  content: "\F348"; }

.mdi-magnify:before {
  content: "\F349"; }

.mdi-magnify-minus:before {
  content: "\F34A"; }

.mdi-magnify-minus-outline:before {
  content: "\F6EB"; }

.mdi-magnify-plus:before {
  content: "\F34B"; }

.mdi-magnify-plus-outline:before {
  content: "\F6EC"; }

.mdi-mail-ru:before {
  content: "\F34C"; }

.mdi-mailbox:before {
  content: "\F6ED"; }

.mdi-map:before {
  content: "\F34D"; }

.mdi-map-marker:before {
  content: "\F34E"; }

.mdi-map-marker-circle:before {
  content: "\F34F"; }

.mdi-map-marker-distance:before {
  content: "\F8EF"; }

.mdi-map-marker-minus:before {
  content: "\F650"; }

.mdi-map-marker-multiple:before {
  content: "\F350"; }

.mdi-map-marker-off:before {
  content: "\F351"; }

.mdi-map-marker-outline:before {
  content: "\F7D8"; }

.mdi-map-marker-plus:before {
  content: "\F651"; }

.mdi-map-marker-radius:before {
  content: "\F352"; }

.mdi-margin:before {
  content: "\F353"; }

.mdi-markdown:before {
  content: "\F354"; }

.mdi-marker:before {
  content: "\F652"; }

.mdi-marker-check:before {
  content: "\F355"; }

.mdi-material-ui:before {
  content: "\F357"; }

.mdi-math-compass:before {
  content: "\F358"; }

.mdi-matrix:before {
  content: "\F628"; }

.mdi-maxcdn:before {
  content: "\F359"; }

.mdi-medical-bag:before {
  content: "\F6EE"; }

.mdi-medium:before {
  content: "\F35A"; }

.mdi-memory:before {
  content: "\F35B"; }

.mdi-menu:before {
  content: "\F35C"; }

.mdi-menu-down:before {
  content: "\F35D"; }

.mdi-menu-down-outline:before {
  content: "\F6B5"; }

.mdi-menu-left:before {
  content: "\F35E"; }

.mdi-menu-right:before {
  content: "\F35F"; }

.mdi-menu-up:before {
  content: "\F360"; }

.mdi-menu-up-outline:before {
  content: "\F6B6"; }

.mdi-message:before {
  content: "\F361"; }

.mdi-message-alert:before {
  content: "\F362"; }

.mdi-message-bulleted:before {
  content: "\F6A1"; }

.mdi-message-bulleted-off:before {
  content: "\F6A2"; }

.mdi-message-draw:before {
  content: "\F363"; }

.mdi-message-image:before {
  content: "\F364"; }

.mdi-message-outline:before {
  content: "\F365"; }

.mdi-message-plus:before {
  content: "\F653"; }

.mdi-message-processing:before {
  content: "\F366"; }

.mdi-message-reply:before {
  content: "\F367"; }

.mdi-message-reply-text:before {
  content: "\F368"; }

.mdi-message-settings:before {
  content: "\F6EF"; }

.mdi-message-settings-variant:before {
  content: "\F6F0"; }

.mdi-message-text:before {
  content: "\F369"; }

.mdi-message-text-outline:before {
  content: "\F36A"; }

.mdi-message-video:before {
  content: "\F36B"; }

.mdi-meteor:before {
  content: "\F629"; }

.mdi-metronome:before {
  content: "\F7D9"; }

.mdi-metronome-tick:before {
  content: "\F7DA"; }

.mdi-micro-sd:before {
  content: "\F7DB"; }

.mdi-microphone:before {
  content: "\F36C"; }

.mdi-microphone-minus:before {
  content: "\F8B2"; }

.mdi-microphone-off:before {
  content: "\F36D"; }

.mdi-microphone-outline:before {
  content: "\F36E"; }

.mdi-microphone-plus:before {
  content: "\F8B3"; }

.mdi-microphone-settings:before {
  content: "\F36F"; }

.mdi-microphone-variant:before {
  content: "\F370"; }

.mdi-microphone-variant-off:before {
  content: "\F371"; }

.mdi-microscope:before {
  content: "\F654"; }

.mdi-microsoft:before {
  content: "\F372"; }

.mdi-midi:before {
  content: "\F8F0"; }

.mdi-midi-port:before {
  content: "\F8F1"; }

.mdi-minecraft:before {
  content: "\F373"; }

.mdi-minus:before {
  content: "\F374"; }

.mdi-minus-box:before {
  content: "\F375"; }

.mdi-minus-box-outline:before {
  content: "\F6F1"; }

.mdi-minus-circle:before {
  content: "\F376"; }

.mdi-minus-circle-outline:before {
  content: "\F377"; }

.mdi-minus-network:before {
  content: "\F378"; }

.mdi-mixcloud:before {
  content: "\F62A"; }

.mdi-mixed-reality:before {
  content: "\F87E"; }

.mdi-mixer:before {
  content: "\F7DC"; }

.mdi-monitor:before {
  content: "\F379"; }

.mdi-monitor-multiple:before {
  content: "\F37A"; }

.mdi-more:before {
  content: "\F37B"; }

.mdi-motorbike:before {
  content: "\F37C"; }

.mdi-mouse:before {
  content: "\F37D"; }

.mdi-mouse-off:before {
  content: "\F37E"; }

.mdi-mouse-variant:before {
  content: "\F37F"; }

.mdi-mouse-variant-off:before {
  content: "\F380"; }

.mdi-move-resize:before {
  content: "\F655"; }

.mdi-move-resize-variant:before {
  content: "\F656"; }

.mdi-movie:before {
  content: "\F381"; }

.mdi-movie-roll:before {
  content: "\F7DD"; }

.mdi-multiplication:before {
  content: "\F382"; }

.mdi-multiplication-box:before {
  content: "\F383"; }

.mdi-mushroom:before {
  content: "\F7DE"; }

.mdi-mushroom-outline:before {
  content: "\F7DF"; }

.mdi-music:before {
  content: "\F759"; }

.mdi-music-box:before {
  content: "\F384"; }

.mdi-music-box-outline:before {
  content: "\F385"; }

.mdi-music-circle:before {
  content: "\F386"; }

.mdi-music-note:before {
  content: "\F387"; }

.mdi-music-note-bluetooth:before {
  content: "\F5FE"; }

.mdi-music-note-bluetooth-off:before {
  content: "\F5FF"; }

.mdi-music-note-eighth:before {
  content: "\F388"; }

.mdi-music-note-half:before {
  content: "\F389"; }

.mdi-music-note-off:before {
  content: "\F38A"; }

.mdi-music-note-quarter:before {
  content: "\F38B"; }

.mdi-music-note-sixteenth:before {
  content: "\F38C"; }

.mdi-music-note-whole:before {
  content: "\F38D"; }

.mdi-music-off:before {
  content: "\F75A"; }

.mdi-nas:before {
  content: "\F8F2"; }

.mdi-nativescript:before {
  content: "\F87F"; }

.mdi-nature:before {
  content: "\F38E"; }

.mdi-nature-people:before {
  content: "\F38F"; }

.mdi-navigation:before {
  content: "\F390"; }

.mdi-near-me:before {
  content: "\F5CD"; }

.mdi-needle:before {
  content: "\F391"; }

.mdi-netflix:before {
  content: "\F745"; }

.mdi-network:before {
  content: "\F6F2"; }

.mdi-network-strength-1:before {
  content: "\F8F3"; }

.mdi-network-strength-1-alert:before {
  content: "\F8F4"; }

.mdi-network-strength-2:before {
  content: "\F8F5"; }

.mdi-network-strength-2-alert:before {
  content: "\F8F6"; }

.mdi-network-strength-3:before {
  content: "\F8F7"; }

.mdi-network-strength-3-alert:before {
  content: "\F8F8"; }

.mdi-network-strength-4:before {
  content: "\F8F9"; }

.mdi-network-strength-4-alert:before {
  content: "\F8FA"; }

.mdi-network-strength-off:before {
  content: "\F8FB"; }

.mdi-network-strength-off-outline:before {
  content: "\F8FC"; }

.mdi-network-strength-outline:before {
  content: "\F8FD"; }

.mdi-new-box:before {
  content: "\F394"; }

.mdi-newspaper:before {
  content: "\F395"; }

.mdi-nfc:before {
  content: "\F396"; }

.mdi-nfc-tap:before {
  content: "\F397"; }

.mdi-nfc-variant:before {
  content: "\F398"; }

.mdi-ninja:before {
  content: "\F773"; }

.mdi-nintendo-switch:before {
  content: "\F7E0"; }

.mdi-nodejs:before {
  content: "\F399"; }

.mdi-note:before {
  content: "\F39A"; }

.mdi-note-multiple:before {
  content: "\F6B7"; }

.mdi-note-multiple-outline:before {
  content: "\F6B8"; }

.mdi-note-outline:before {
  content: "\F39B"; }

.mdi-note-plus:before {
  content: "\F39C"; }

.mdi-note-plus-outline:before {
  content: "\F39D"; }

.mdi-note-text:before {
  content: "\F39E"; }

.mdi-notebook:before {
  content: "\F82D"; }

.mdi-notification-clear-all:before {
  content: "\F39F"; }

.mdi-npm:before {
  content: "\F6F6"; }

.mdi-nuke:before {
  content: "\F6A3"; }

.mdi-null:before {
  content: "\F7E1"; }

.mdi-numeric:before {
  content: "\F3A0"; }

.mdi-numeric-0-box:before {
  content: "\F3A1"; }

.mdi-numeric-0-box-multiple-outline:before {
  content: "\F3A2"; }

.mdi-numeric-0-box-outline:before {
  content: "\F3A3"; }

.mdi-numeric-1-box:before {
  content: "\F3A4"; }

.mdi-numeric-1-box-multiple-outline:before {
  content: "\F3A5"; }

.mdi-numeric-1-box-outline:before {
  content: "\F3A6"; }

.mdi-numeric-2-box:before {
  content: "\F3A7"; }

.mdi-numeric-2-box-multiple-outline:before {
  content: "\F3A8"; }

.mdi-numeric-2-box-outline:before {
  content: "\F3A9"; }

.mdi-numeric-3-box:before {
  content: "\F3AA"; }

.mdi-numeric-3-box-multiple-outline:before {
  content: "\F3AB"; }

.mdi-numeric-3-box-outline:before {
  content: "\F3AC"; }

.mdi-numeric-4-box:before {
  content: "\F3AD"; }

.mdi-numeric-4-box-multiple-outline:before {
  content: "\F3AE"; }

.mdi-numeric-4-box-outline:before {
  content: "\F3AF"; }

.mdi-numeric-5-box:before {
  content: "\F3B0"; }

.mdi-numeric-5-box-multiple-outline:before {
  content: "\F3B1"; }

.mdi-numeric-5-box-outline:before {
  content: "\F3B2"; }

.mdi-numeric-6-box:before {
  content: "\F3B3"; }

.mdi-numeric-6-box-multiple-outline:before {
  content: "\F3B4"; }

.mdi-numeric-6-box-outline:before {
  content: "\F3B5"; }

.mdi-numeric-7-box:before {
  content: "\F3B6"; }

.mdi-numeric-7-box-multiple-outline:before {
  content: "\F3B7"; }

.mdi-numeric-7-box-outline:before {
  content: "\F3B8"; }

.mdi-numeric-8-box:before {
  content: "\F3B9"; }

.mdi-numeric-8-box-multiple-outline:before {
  content: "\F3BA"; }

.mdi-numeric-8-box-outline:before {
  content: "\F3BB"; }

.mdi-numeric-9-box:before {
  content: "\F3BC"; }

.mdi-numeric-9-box-multiple-outline:before {
  content: "\F3BD"; }

.mdi-numeric-9-box-outline:before {
  content: "\F3BE"; }

.mdi-numeric-9-plus-box:before {
  content: "\F3BF"; }

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\F3C0"; }

.mdi-numeric-9-plus-box-outline:before {
  content: "\F3C1"; }

.mdi-nut:before {
  content: "\F6F7"; }

.mdi-nutrition:before {
  content: "\F3C2"; }

.mdi-oar:before {
  content: "\F67B"; }

.mdi-octagon:before {
  content: "\F3C3"; }

.mdi-octagon-outline:before {
  content: "\F3C4"; }

.mdi-octagram:before {
  content: "\F6F8"; }

.mdi-octagram-outline:before {
  content: "\F774"; }

.mdi-odnoklassniki:before {
  content: "\F3C5"; }

.mdi-office:before {
  content: "\F3C6"; }

.mdi-oil:before {
  content: "\F3C7"; }

.mdi-oil-temperature:before {
  content: "\F3C8"; }

.mdi-omega:before {
  content: "\F3C9"; }

.mdi-onedrive:before {
  content: "\F3CA"; }

.mdi-onenote:before {
  content: "\F746"; }

.mdi-onepassword:before {
  content: "\F880"; }

.mdi-opacity:before {
  content: "\F5CC"; }

.mdi-open-in-app:before {
  content: "\F3CB"; }

.mdi-open-in-new:before {
  content: "\F3CC"; }

.mdi-openid:before {
  content: "\F3CD"; }

.mdi-opera:before {
  content: "\F3CE"; }

.mdi-orbit:before {
  content: "\F018"; }

.mdi-ornament:before {
  content: "\F3CF"; }

.mdi-ornament-variant:before {
  content: "\F3D0"; }

.mdi-owl:before {
  content: "\F3D2"; }

.mdi-package:before {
  content: "\F3D3"; }

.mdi-package-down:before {
  content: "\F3D4"; }

.mdi-package-up:before {
  content: "\F3D5"; }

.mdi-package-variant:before {
  content: "\F3D6"; }

.mdi-package-variant-closed:before {
  content: "\F3D7"; }

.mdi-page-first:before {
  content: "\F600"; }

.mdi-page-last:before {
  content: "\F601"; }

.mdi-page-layout-body:before {
  content: "\F6F9"; }

.mdi-page-layout-footer:before {
  content: "\F6FA"; }

.mdi-page-layout-header:before {
  content: "\F6FB"; }

.mdi-page-layout-sidebar-left:before {
  content: "\F6FC"; }

.mdi-page-layout-sidebar-right:before {
  content: "\F6FD"; }

.mdi-palette:before {
  content: "\F3D8"; }

.mdi-palette-advanced:before {
  content: "\F3D9"; }

.mdi-palette-swatch:before {
  content: "\F8B4"; }

.mdi-panda:before {
  content: "\F3DA"; }

.mdi-pandora:before {
  content: "\F3DB"; }

.mdi-panorama:before {
  content: "\F3DC"; }

.mdi-panorama-fisheye:before {
  content: "\F3DD"; }

.mdi-panorama-horizontal:before {
  content: "\F3DE"; }

.mdi-panorama-vertical:before {
  content: "\F3DF"; }

.mdi-panorama-wide-angle:before {
  content: "\F3E0"; }

.mdi-paper-cut-vertical:before {
  content: "\F3E1"; }

.mdi-paperclip:before {
  content: "\F3E2"; }

.mdi-parking:before {
  content: "\F3E3"; }

.mdi-passport:before {
  content: "\F7E2"; }

.mdi-patreon:before {
  content: "\F881"; }

.mdi-pause:before {
  content: "\F3E4"; }

.mdi-pause-circle:before {
  content: "\F3E5"; }

.mdi-pause-circle-outline:before {
  content: "\F3E6"; }

.mdi-pause-octagon:before {
  content: "\F3E7"; }

.mdi-pause-octagon-outline:before {
  content: "\F3E8"; }

.mdi-paw:before {
  content: "\F3E9"; }

.mdi-paw-off:before {
  content: "\F657"; }

.mdi-paypal:before {
  content: "\F882"; }

.mdi-peace:before {
  content: "\F883"; }

.mdi-pen:before {
  content: "\F3EA"; }

.mdi-pencil:before {
  content: "\F3EB"; }

.mdi-pencil-box:before {
  content: "\F3EC"; }

.mdi-pencil-box-outline:before {
  content: "\F3ED"; }

.mdi-pencil-circle:before {
  content: "\F6FE"; }

.mdi-pencil-circle-outline:before {
  content: "\F775"; }

.mdi-pencil-lock:before {
  content: "\F3EE"; }

.mdi-pencil-off:before {
  content: "\F3EF"; }

.mdi-pentagon:before {
  content: "\F6FF"; }

.mdi-pentagon-outline:before {
  content: "\F700"; }

.mdi-percent:before {
  content: "\F3F0"; }

.mdi-periodic-table:before {
  content: "\F8B5"; }

.mdi-periodic-table-co2:before {
  content: "\F7E3"; }

.mdi-periscope:before {
  content: "\F747"; }

.mdi-pharmacy:before {
  content: "\F3F1"; }

.mdi-phone:before {
  content: "\F3F2"; }

.mdi-phone-bluetooth:before {
  content: "\F3F3"; }

.mdi-phone-classic:before {
  content: "\F602"; }

.mdi-phone-forward:before {
  content: "\F3F4"; }

.mdi-phone-hangup:before {
  content: "\F3F5"; }

.mdi-phone-in-talk:before {
  content: "\F3F6"; }

.mdi-phone-incoming:before {
  content: "\F3F7"; }

.mdi-phone-locked:before {
  content: "\F3F8"; }

.mdi-phone-log:before {
  content: "\F3F9"; }

.mdi-phone-minus:before {
  content: "\F658"; }

.mdi-phone-missed:before {
  content: "\F3FA"; }

.mdi-phone-outgoing:before {
  content: "\F3FB"; }

.mdi-phone-paused:before {
  content: "\F3FC"; }

.mdi-phone-plus:before {
  content: "\F659"; }

.mdi-phone-return:before {
  content: "\F82E"; }

.mdi-phone-rotate-landscape:before {
  content: "\F884"; }

.mdi-phone-rotate-portrait:before {
  content: "\F885"; }

.mdi-phone-settings:before {
  content: "\F3FD"; }

.mdi-phone-voip:before {
  content: "\F3FE"; }

.mdi-pi:before {
  content: "\F3FF"; }

.mdi-pi-box:before {
  content: "\F400"; }

.mdi-piano:before {
  content: "\F67C"; }

.mdi-pickaxe:before {
  content: "\F8B6"; }

.mdi-pier:before {
  content: "\F886"; }

.mdi-pier-crane:before {
  content: "\F887"; }

.mdi-pig:before {
  content: "\F401"; }

.mdi-pill:before {
  content: "\F402"; }

.mdi-pillar:before {
  content: "\F701"; }

.mdi-pin:before {
  content: "\F403"; }

.mdi-pin-off:before {
  content: "\F404"; }

.mdi-pin-off-outline:before {
  content: "\F92F"; }

.mdi-pin-outline:before {
  content: "\F930"; }

.mdi-pine-tree:before {
  content: "\F405"; }

.mdi-pine-tree-box:before {
  content: "\F406"; }

.mdi-pinterest:before {
  content: "\F407"; }

.mdi-pinterest-box:before {
  content: "\F408"; }

.mdi-pipe:before {
  content: "\F7E4"; }

.mdi-pipe-disconnected:before {
  content: "\F7E5"; }

.mdi-pipe-leak:before {
  content: "\F888"; }

.mdi-pistol:before {
  content: "\F702"; }

.mdi-piston:before {
  content: "\F889"; }

.mdi-pizza:before {
  content: "\F409"; }

.mdi-plane-shield:before {
  content: "\F6BA"; }

.mdi-play:before {
  content: "\F40A"; }

.mdi-play-box-outline:before {
  content: "\F40B"; }

.mdi-play-circle:before {
  content: "\F40C"; }

.mdi-play-circle-outline:before {
  content: "\F40D"; }

.mdi-play-network:before {
  content: "\F88A"; }

.mdi-play-pause:before {
  content: "\F40E"; }

.mdi-play-protected-content:before {
  content: "\F40F"; }

.mdi-play-speed:before {
  content: "\F8FE"; }

.mdi-playlist-check:before {
  content: "\F5C7"; }

.mdi-playlist-edit:before {
  content: "\F8FF"; }

.mdi-playlist-minus:before {
  content: "\F410"; }

.mdi-playlist-play:before {
  content: "\F411"; }

.mdi-playlist-plus:before {
  content: "\F412"; }

.mdi-playlist-remove:before {
  content: "\F413"; }

.mdi-playstation:before {
  content: "\F414"; }

.mdi-plex:before {
  content: "\F6B9"; }

.mdi-plus:before {
  content: "\F415"; }

.mdi-plus-box:before {
  content: "\F416"; }

.mdi-plus-box-outline:before {
  content: "\F703"; }

.mdi-plus-circle:before {
  content: "\F417"; }

.mdi-plus-circle-multiple-outline:before {
  content: "\F418"; }

.mdi-plus-circle-outline:before {
  content: "\F419"; }

.mdi-plus-network:before {
  content: "\F41A"; }

.mdi-plus-one:before {
  content: "\F41B"; }

.mdi-plus-outline:before {
  content: "\F704"; }

.mdi-pocket:before {
  content: "\F41C"; }

.mdi-pokeball:before {
  content: "\F41D"; }

.mdi-poker-chip:before {
  content: "\F82F"; }

.mdi-polaroid:before {
  content: "\F41E"; }

.mdi-poll:before {
  content: "\F41F"; }

.mdi-poll-box:before {
  content: "\F420"; }

.mdi-polymer:before {
  content: "\F421"; }

.mdi-pool:before {
  content: "\F606"; }

.mdi-popcorn:before {
  content: "\F422"; }

.mdi-pot:before {
  content: "\F65A"; }

.mdi-pot-mix:before {
  content: "\F65B"; }

.mdi-pound:before {
  content: "\F423"; }

.mdi-pound-box:before {
  content: "\F424"; }

.mdi-power:before {
  content: "\F425"; }

.mdi-power-cycle:before {
  content: "\F900"; }

.mdi-power-off:before {
  content: "\F901"; }

.mdi-power-on:before {
  content: "\F902"; }

.mdi-power-plug:before {
  content: "\F6A4"; }

.mdi-power-plug-off:before {
  content: "\F6A5"; }

.mdi-power-settings:before {
  content: "\F426"; }

.mdi-power-sleep:before {
  content: "\F903"; }

.mdi-power-socket:before {
  content: "\F427"; }

.mdi-power-socket-au:before {
  content: "\F904"; }

.mdi-power-socket-eu:before {
  content: "\F7E6"; }

.mdi-power-socket-uk:before {
  content: "\F7E7"; }

.mdi-power-socket-us:before {
  content: "\F7E8"; }

.mdi-power-standby:before {
  content: "\F905"; }

.mdi-prescription:before {
  content: "\F705"; }

.mdi-presentation:before {
  content: "\F428"; }

.mdi-presentation-play:before {
  content: "\F429"; }

.mdi-printer:before {
  content: "\F42A"; }

.mdi-printer-3d:before {
  content: "\F42B"; }

.mdi-printer-alert:before {
  content: "\F42C"; }

.mdi-printer-settings:before {
  content: "\F706"; }

.mdi-priority-high:before {
  content: "\F603"; }

.mdi-priority-low:before {
  content: "\F604"; }

.mdi-professional-hexagon:before {
  content: "\F42D"; }

.mdi-projector:before {
  content: "\F42E"; }

.mdi-projector-screen:before {
  content: "\F42F"; }

.mdi-publish:before {
  content: "\F6A6"; }

.mdi-pulse:before {
  content: "\F430"; }

.mdi-puzzle:before {
  content: "\F431"; }

.mdi-qqchat:before {
  content: "\F605"; }

.mdi-qrcode:before {
  content: "\F432"; }

.mdi-qrcode-edit:before {
  content: "\F8B7"; }

.mdi-qrcode-scan:before {
  content: "\F433"; }

.mdi-quadcopter:before {
  content: "\F434"; }

.mdi-quality-high:before {
  content: "\F435"; }

.mdi-quicktime:before {
  content: "\F436"; }

.mdi-rabbit:before {
  content: "\F906"; }

.mdi-radar:before {
  content: "\F437"; }

.mdi-radiator:before {
  content: "\F438"; }

.mdi-radio:before {
  content: "\F439"; }

.mdi-radio-handheld:before {
  content: "\F43A"; }

.mdi-radio-tower:before {
  content: "\F43B"; }

.mdi-radioactive:before {
  content: "\F43C"; }

.mdi-radiobox-blank:before {
  content: "\F43D"; }

.mdi-radiobox-marked:before {
  content: "\F43E"; }

.mdi-raspberrypi:before {
  content: "\F43F"; }

.mdi-ray-end:before {
  content: "\F440"; }

.mdi-ray-end-arrow:before {
  content: "\F441"; }

.mdi-ray-start:before {
  content: "\F442"; }

.mdi-ray-start-arrow:before {
  content: "\F443"; }

.mdi-ray-start-end:before {
  content: "\F444"; }

.mdi-ray-vertex:before {
  content: "\F445"; }

.mdi-react:before {
  content: "\F707"; }

.mdi-read:before {
  content: "\F447"; }

.mdi-receipt:before {
  content: "\F449"; }

.mdi-record:before {
  content: "\F44A"; }

.mdi-record-rec:before {
  content: "\F44B"; }

.mdi-recycle:before {
  content: "\F44C"; }

.mdi-reddit:before {
  content: "\F44D"; }

.mdi-redo:before {
  content: "\F44E"; }

.mdi-redo-variant:before {
  content: "\F44F"; }

.mdi-refresh:before {
  content: "\F450"; }

.mdi-regex:before {
  content: "\F451"; }

.mdi-relative-scale:before {
  content: "\F452"; }

.mdi-reload:before {
  content: "\F453"; }

.mdi-reminder:before {
  content: "\F88B"; }

.mdi-remote:before {
  content: "\F454"; }

.mdi-remote-desktop:before {
  content: "\F8B8"; }

.mdi-rename-box:before {
  content: "\F455"; }

.mdi-reorder-horizontal:before {
  content: "\F687"; }

.mdi-reorder-vertical:before {
  content: "\F688"; }

.mdi-repeat:before {
  content: "\F456"; }

.mdi-repeat-off:before {
  content: "\F457"; }

.mdi-repeat-once:before {
  content: "\F458"; }

.mdi-replay:before {
  content: "\F459"; }

.mdi-reply:before {
  content: "\F45A"; }

.mdi-reply-all:before {
  content: "\F45B"; }

.mdi-reproduction:before {
  content: "\F45C"; }

.mdi-resize-bottom-right:before {
  content: "\F45D"; }

.mdi-responsive:before {
  content: "\F45E"; }

.mdi-restart:before {
  content: "\F708"; }

.mdi-restore:before {
  content: "\F6A7"; }

.mdi-rewind:before {
  content: "\F45F"; }

.mdi-rewind-outline:before {
  content: "\F709"; }

.mdi-rhombus:before {
  content: "\F70A"; }

.mdi-rhombus-outline:before {
  content: "\F70B"; }

.mdi-ribbon:before {
  content: "\F460"; }

.mdi-rice:before {
  content: "\F7E9"; }

.mdi-ring:before {
  content: "\F7EA"; }

.mdi-road:before {
  content: "\F461"; }

.mdi-road-variant:before {
  content: "\F462"; }

.mdi-robot:before {
  content: "\F6A8"; }

.mdi-robot-vacuum:before {
  content: "\F70C"; }

.mdi-robot-vacuum-variant:before {
  content: "\F907"; }

.mdi-rocket:before {
  content: "\F463"; }

.mdi-room-service:before {
  content: "\F88C"; }

.mdi-rotate-3d:before {
  content: "\F464"; }

.mdi-rotate-left:before {
  content: "\F465"; }

.mdi-rotate-left-variant:before {
  content: "\F466"; }

.mdi-rotate-right:before {
  content: "\F467"; }

.mdi-rotate-right-variant:before {
  content: "\F468"; }

.mdi-rounded-corner:before {
  content: "\F607"; }

.mdi-router-wireless:before {
  content: "\F469"; }

.mdi-routes:before {
  content: "\F46A"; }

.mdi-rowing:before {
  content: "\F608"; }

.mdi-rss:before {
  content: "\F46B"; }

.mdi-rss-box:before {
  content: "\F46C"; }

.mdi-ruler:before {
  content: "\F46D"; }

.mdi-run:before {
  content: "\F70D"; }

.mdi-run-fast:before {
  content: "\F46E"; }

.mdi-sale:before {
  content: "\F46F"; }

.mdi-salesforce:before {
  content: "\F88D"; }

.mdi-sass:before {
  content: "\F7EB"; }

.mdi-satellite:before {
  content: "\F470"; }

.mdi-satellite-uplink:before {
  content: "\F908"; }

.mdi-satellite-variant:before {
  content: "\F471"; }

.mdi-sausage:before {
  content: "\F8B9"; }

.mdi-saxophone:before {
  content: "\F609"; }

.mdi-scale:before {
  content: "\F472"; }

.mdi-scale-balance:before {
  content: "\F5D1"; }

.mdi-scale-bathroom:before {
  content: "\F473"; }

.mdi-scanner:before {
  content: "\F6AA"; }

.mdi-scanner-off:before {
  content: "\F909"; }

.mdi-school:before {
  content: "\F474"; }

.mdi-screen-rotation:before {
  content: "\F475"; }

.mdi-screen-rotation-lock:before {
  content: "\F476"; }

.mdi-screwdriver:before {
  content: "\F477"; }

.mdi-script:before {
  content: "\F478"; }

.mdi-sd:before {
  content: "\F479"; }

.mdi-seal:before {
  content: "\F47A"; }

.mdi-search-web:before {
  content: "\F70E"; }

.mdi-seat-flat:before {
  content: "\F47B"; }

.mdi-seat-flat-angled:before {
  content: "\F47C"; }

.mdi-seat-individual-suite:before {
  content: "\F47D"; }

.mdi-seat-legroom-extra:before {
  content: "\F47E"; }

.mdi-seat-legroom-normal:before {
  content: "\F47F"; }

.mdi-seat-legroom-reduced:before {
  content: "\F480"; }

.mdi-seat-recline-extra:before {
  content: "\F481"; }

.mdi-seat-recline-normal:before {
  content: "\F482"; }

.mdi-security:before {
  content: "\F483"; }

.mdi-security-account:before {
  content: "\F88E"; }

.mdi-security-home:before {
  content: "\F689"; }

.mdi-security-network:before {
  content: "\F484"; }

.mdi-select:before {
  content: "\F485"; }

.mdi-select-all:before {
  content: "\F486"; }

.mdi-select-inverse:before {
  content: "\F487"; }

.mdi-select-off:before {
  content: "\F488"; }

.mdi-selection:before {
  content: "\F489"; }

.mdi-selection-off:before {
  content: "\F776"; }

.mdi-send:before {
  content: "\F48A"; }

.mdi-send-secure:before {
  content: "\F7EC"; }

.mdi-serial-port:before {
  content: "\F65C"; }

.mdi-server:before {
  content: "\F48B"; }

.mdi-server-minus:before {
  content: "\F48C"; }

.mdi-server-network:before {
  content: "\F48D"; }

.mdi-server-network-off:before {
  content: "\F48E"; }

.mdi-server-off:before {
  content: "\F48F"; }

.mdi-server-plus:before {
  content: "\F490"; }

.mdi-server-remove:before {
  content: "\F491"; }

.mdi-server-security:before {
  content: "\F492"; }

.mdi-set-all:before {
  content: "\F777"; }

.mdi-set-center:before {
  content: "\F778"; }

.mdi-set-center-right:before {
  content: "\F779"; }

.mdi-set-left:before {
  content: "\F77A"; }

.mdi-set-left-center:before {
  content: "\F77B"; }

.mdi-set-left-right:before {
  content: "\F77C"; }

.mdi-set-none:before {
  content: "\F77D"; }

.mdi-set-right:before {
  content: "\F77E"; }

.mdi-settings:before {
  content: "\F493"; }

.mdi-settings-box:before {
  content: "\F494"; }

.mdi-settings-outline:before {
  content: "\F8BA"; }

.mdi-shape:before {
  content: "\F830"; }

.mdi-shape-circle-plus:before {
  content: "\F65D"; }

.mdi-shape-outline:before {
  content: "\F831"; }

.mdi-shape-plus:before {
  content: "\F495"; }

.mdi-shape-polygon-plus:before {
  content: "\F65E"; }

.mdi-shape-rectangle-plus:before {
  content: "\F65F"; }

.mdi-shape-square-plus:before {
  content: "\F660"; }

.mdi-share:before {
  content: "\F496"; }

.mdi-share-outline:before {
  content: "\F931"; }

.mdi-share-variant:before {
  content: "\F497"; }

.mdi-shield:before {
  content: "\F498"; }

.mdi-shield-half-full:before {
  content: "\F77F"; }

.mdi-shield-outline:before {
  content: "\F499"; }

.mdi-ship-wheel:before {
  content: "\F832"; }

.mdi-shopping:before {
  content: "\F49A"; }

.mdi-shopping-music:before {
  content: "\F49B"; }

.mdi-shovel:before {
  content: "\F70F"; }

.mdi-shovel-off:before {
  content: "\F710"; }

.mdi-shredder:before {
  content: "\F49C"; }

.mdi-shuffle:before {
  content: "\F49D"; }

.mdi-shuffle-disabled:before {
  content: "\F49E"; }

.mdi-shuffle-variant:before {
  content: "\F49F"; }

.mdi-sigma:before {
  content: "\F4A0"; }

.mdi-sigma-lower:before {
  content: "\F62B"; }

.mdi-sign-caution:before {
  content: "\F4A1"; }

.mdi-sign-direction:before {
  content: "\F780"; }

.mdi-sign-text:before {
  content: "\F781"; }

.mdi-signal:before {
  content: "\F4A2"; }

.mdi-signal-2g:before {
  content: "\F711"; }

.mdi-signal-3g:before {
  content: "\F712"; }

.mdi-signal-4g:before {
  content: "\F713"; }

.mdi-signal-cellular-1:before {
  content: "\F8BB"; }

.mdi-signal-cellular-2:before {
  content: "\F8BC"; }

.mdi-signal-cellular-3:before {
  content: "\F8BD"; }

.mdi-signal-cellular-outline:before {
  content: "\F8BE"; }

.mdi-signal-hspa:before {
  content: "\F714"; }

.mdi-signal-hspa-plus:before {
  content: "\F715"; }

.mdi-signal-off:before {
  content: "\F782"; }

.mdi-signal-variant:before {
  content: "\F60A"; }

.mdi-silverware:before {
  content: "\F4A3"; }

.mdi-silverware-fork:before {
  content: "\F4A4"; }

.mdi-silverware-spoon:before {
  content: "\F4A5"; }

.mdi-silverware-variant:before {
  content: "\F4A6"; }

.mdi-sim:before {
  content: "\F4A7"; }

.mdi-sim-alert:before {
  content: "\F4A8"; }

.mdi-sim-off:before {
  content: "\F4A9"; }

.mdi-sitemap:before {
  content: "\F4AA"; }

.mdi-skip-backward:before {
  content: "\F4AB"; }

.mdi-skip-forward:before {
  content: "\F4AC"; }

.mdi-skip-next:before {
  content: "\F4AD"; }

.mdi-skip-next-circle:before {
  content: "\F661"; }

.mdi-skip-next-circle-outline:before {
  content: "\F662"; }

.mdi-skip-previous:before {
  content: "\F4AE"; }

.mdi-skip-previous-circle:before {
  content: "\F663"; }

.mdi-skip-previous-circle-outline:before {
  content: "\F664"; }

.mdi-skull:before {
  content: "\F68B"; }

.mdi-skype:before {
  content: "\F4AF"; }

.mdi-skype-business:before {
  content: "\F4B0"; }

.mdi-slack:before {
  content: "\F4B1"; }

.mdi-slackware:before {
  content: "\F90A"; }

.mdi-sleep:before {
  content: "\F4B2"; }

.mdi-sleep-off:before {
  content: "\F4B3"; }

.mdi-smoke-detector:before {
  content: "\F392"; }

.mdi-smoking:before {
  content: "\F4B4"; }

.mdi-smoking-off:before {
  content: "\F4B5"; }

.mdi-snapchat:before {
  content: "\F4B6"; }

.mdi-snowflake:before {
  content: "\F716"; }

.mdi-snowman:before {
  content: "\F4B7"; }

.mdi-soccer:before {
  content: "\F4B8"; }

.mdi-soccer-field:before {
  content: "\F833"; }

.mdi-sofa:before {
  content: "\F4B9"; }

.mdi-solid:before {
  content: "\F68C"; }

.mdi-sort:before {
  content: "\F4BA"; }

.mdi-sort-alphabetical:before {
  content: "\F4BB"; }

.mdi-sort-ascending:before {
  content: "\F4BC"; }

.mdi-sort-descending:before {
  content: "\F4BD"; }

.mdi-sort-numeric:before {
  content: "\F4BE"; }

.mdi-sort-variant:before {
  content: "\F4BF"; }

.mdi-soundcloud:before {
  content: "\F4C0"; }

.mdi-source-branch:before {
  content: "\F62C"; }

.mdi-source-commit:before {
  content: "\F717"; }

.mdi-source-commit-end:before {
  content: "\F718"; }

.mdi-source-commit-end-local:before {
  content: "\F719"; }

.mdi-source-commit-local:before {
  content: "\F71A"; }

.mdi-source-commit-next-local:before {
  content: "\F71B"; }

.mdi-source-commit-start:before {
  content: "\F71C"; }

.mdi-source-commit-start-next-local:before {
  content: "\F71D"; }

.mdi-source-fork:before {
  content: "\F4C1"; }

.mdi-source-merge:before {
  content: "\F62D"; }

.mdi-source-pull:before {
  content: "\F4C2"; }

.mdi-soy-sauce:before {
  content: "\F7ED"; }

.mdi-speaker:before {
  content: "\F4C3"; }

.mdi-speaker-off:before {
  content: "\F4C4"; }

.mdi-speaker-wireless:before {
  content: "\F71E"; }

.mdi-speedometer:before {
  content: "\F4C5"; }

.mdi-spellcheck:before {
  content: "\F4C6"; }

.mdi-spotify:before {
  content: "\F4C7"; }

.mdi-spotlight:before {
  content: "\F4C8"; }

.mdi-spotlight-beam:before {
  content: "\F4C9"; }

.mdi-spray:before {
  content: "\F665"; }

.mdi-square:before {
  content: "\F763"; }

.mdi-square-edit-outline:before {
  content: "\F90B"; }

.mdi-square-inc:before {
  content: "\F4CA"; }

.mdi-square-inc-cash:before {
  content: "\F4CB"; }

.mdi-square-outline:before {
  content: "\F762"; }

.mdi-square-root:before {
  content: "\F783"; }

.mdi-ssh:before {
  content: "\F8BF"; }

.mdi-stack-exchange:before {
  content: "\F60B"; }

.mdi-stack-overflow:before {
  content: "\F4CC"; }

.mdi-stadium:before {
  content: "\F71F"; }

.mdi-stairs:before {
  content: "\F4CD"; }

.mdi-standard-definition:before {
  content: "\F7EE"; }

.mdi-star:before {
  content: "\F4CE"; }

.mdi-star-circle:before {
  content: "\F4CF"; }

.mdi-star-half:before {
  content: "\F4D0"; }

.mdi-star-off:before {
  content: "\F4D1"; }

.mdi-star-outline:before {
  content: "\F4D2"; }

.mdi-steam:before {
  content: "\F4D3"; }

.mdi-steam-box:before {
  content: "\F90C"; }

.mdi-steering:before {
  content: "\F4D4"; }

.mdi-steering-off:before {
  content: "\F90D"; }

.mdi-step-backward:before {
  content: "\F4D5"; }

.mdi-step-backward-2:before {
  content: "\F4D6"; }

.mdi-step-forward:before {
  content: "\F4D7"; }

.mdi-step-forward-2:before {
  content: "\F4D8"; }

.mdi-stethoscope:before {
  content: "\F4D9"; }

.mdi-sticker:before {
  content: "\F5D0"; }

.mdi-sticker-emoji:before {
  content: "\F784"; }

.mdi-stocking:before {
  content: "\F4DA"; }

.mdi-stop:before {
  content: "\F4DB"; }

.mdi-stop-circle:before {
  content: "\F666"; }

.mdi-stop-circle-outline:before {
  content: "\F667"; }

.mdi-store:before {
  content: "\F4DC"; }

.mdi-store-24-hour:before {
  content: "\F4DD"; }

.mdi-stove:before {
  content: "\F4DE"; }

.mdi-subdirectory-arrow-left:before {
  content: "\F60C"; }

.mdi-subdirectory-arrow-right:before {
  content: "\F60D"; }

.mdi-subway:before {
  content: "\F6AB"; }

.mdi-subway-variant:before {
  content: "\F4DF"; }

.mdi-summit:before {
  content: "\F785"; }

.mdi-sunglasses:before {
  content: "\F4E0"; }

.mdi-surround-sound:before {
  content: "\F5C5"; }

.mdi-surround-sound-2-0:before {
  content: "\F7EF"; }

.mdi-surround-sound-3-1:before {
  content: "\F7F0"; }

.mdi-surround-sound-5-1:before {
  content: "\F7F1"; }

.mdi-surround-sound-7-1:before {
  content: "\F7F2"; }

.mdi-svg:before {
  content: "\F720"; }

.mdi-swap-horizontal:before {
  content: "\F4E1"; }

.mdi-swap-horizontal-variant:before {
  content: "\F8C0"; }

.mdi-swap-vertical:before {
  content: "\F4E2"; }

.mdi-swap-vertical-variant:before {
  content: "\F8C1"; }

.mdi-swim:before {
  content: "\F4E3"; }

.mdi-switch:before {
  content: "\F4E4"; }

.mdi-sword:before {
  content: "\F4E5"; }

.mdi-sword-cross:before {
  content: "\F786"; }

.mdi-sync:before {
  content: "\F4E6"; }

.mdi-sync-alert:before {
  content: "\F4E7"; }

.mdi-sync-off:before {
  content: "\F4E8"; }

.mdi-tab:before {
  content: "\F4E9"; }

.mdi-tab-plus:before {
  content: "\F75B"; }

.mdi-tab-unselected:before {
  content: "\F4EA"; }

.mdi-table:before {
  content: "\F4EB"; }

.mdi-table-column:before {
  content: "\F834"; }

.mdi-table-column-plus-after:before {
  content: "\F4EC"; }

.mdi-table-column-plus-before:before {
  content: "\F4ED"; }

.mdi-table-column-remove:before {
  content: "\F4EE"; }

.mdi-table-column-width:before {
  content: "\F4EF"; }

.mdi-table-edit:before {
  content: "\F4F0"; }

.mdi-table-large:before {
  content: "\F4F1"; }

.mdi-table-of-contents:before {
  content: "\F835"; }

.mdi-table-row:before {
  content: "\F836"; }

.mdi-table-row-height:before {
  content: "\F4F2"; }

.mdi-table-row-plus-after:before {
  content: "\F4F3"; }

.mdi-table-row-plus-before:before {
  content: "\F4F4"; }

.mdi-table-row-remove:before {
  content: "\F4F5"; }

.mdi-table-search:before {
  content: "\F90E"; }

.mdi-table-settings:before {
  content: "\F837"; }

.mdi-tablet:before {
  content: "\F4F6"; }

.mdi-tablet-android:before {
  content: "\F4F7"; }

.mdi-tablet-ipad:before {
  content: "\F4F8"; }

.mdi-taco:before {
  content: "\F761"; }

.mdi-tag:before {
  content: "\F4F9"; }

.mdi-tag-faces:before {
  content: "\F4FA"; }

.mdi-tag-heart:before {
  content: "\F68A"; }

.mdi-tag-minus:before {
  content: "\F90F"; }

.mdi-tag-multiple:before {
  content: "\F4FB"; }

.mdi-tag-outline:before {
  content: "\F4FC"; }

.mdi-tag-plus:before {
  content: "\F721"; }

.mdi-tag-remove:before {
  content: "\F722"; }

.mdi-tag-text-outline:before {
  content: "\F4FD"; }

.mdi-target:before {
  content: "\F4FE"; }

.mdi-taxi:before {
  content: "\F4FF"; }

.mdi-teach:before {
  content: "\F88F"; }

.mdi-teamviewer:before {
  content: "\F500"; }

.mdi-telegram:before {
  content: "\F501"; }

.mdi-television:before {
  content: "\F502"; }

.mdi-television-box:before {
  content: "\F838"; }

.mdi-television-classic:before {
  content: "\F7F3"; }

.mdi-television-classic-off:before {
  content: "\F839"; }

.mdi-television-guide:before {
  content: "\F503"; }

.mdi-television-off:before {
  content: "\F83A"; }

.mdi-temperature-celsius:before {
  content: "\F504"; }

.mdi-temperature-fahrenheit:before {
  content: "\F505"; }

.mdi-temperature-kelvin:before {
  content: "\F506"; }

.mdi-tennis:before {
  content: "\F507"; }

.mdi-tent:before {
  content: "\F508"; }

.mdi-terrain:before {
  content: "\F509"; }

.mdi-test-tube:before {
  content: "\F668"; }

.mdi-test-tube-empty:before {
  content: "\F910"; }

.mdi-test-tube-off:before {
  content: "\F911"; }

.mdi-text-shadow:before {
  content: "\F669"; }

.mdi-text-to-speech:before {
  content: "\F50A"; }

.mdi-text-to-speech-off:before {
  content: "\F50B"; }

.mdi-textbox:before {
  content: "\F60E"; }

.mdi-textbox-password:before {
  content: "\F7F4"; }

.mdi-texture:before {
  content: "\F50C"; }

.mdi-theater:before {
  content: "\F50D"; }

.mdi-theme-light-dark:before {
  content: "\F50E"; }

.mdi-thermometer:before {
  content: "\F50F"; }

.mdi-thermometer-lines:before {
  content: "\F510"; }

.mdi-thermostat:before {
  content: "\F393"; }

.mdi-thermostat-box:before {
  content: "\F890"; }

.mdi-thought-bubble:before {
  content: "\F7F5"; }

.mdi-thought-bubble-outline:before {
  content: "\F7F6"; }

.mdi-thumb-down:before {
  content: "\F511"; }

.mdi-thumb-down-outline:before {
  content: "\F512"; }

.mdi-thumb-up:before {
  content: "\F513"; }

.mdi-thumb-up-outline:before {
  content: "\F514"; }

.mdi-thumbs-up-down:before {
  content: "\F515"; }

.mdi-ticket:before {
  content: "\F516"; }

.mdi-ticket-account:before {
  content: "\F517"; }

.mdi-ticket-confirmation:before {
  content: "\F518"; }

.mdi-ticket-outline:before {
  content: "\F912"; }

.mdi-ticket-percent:before {
  content: "\F723"; }

.mdi-tie:before {
  content: "\F519"; }

.mdi-tilde:before {
  content: "\F724"; }

.mdi-timelapse:before {
  content: "\F51A"; }

.mdi-timer:before {
  content: "\F51B"; }

.mdi-timer-10:before {
  content: "\F51C"; }

.mdi-timer-3:before {
  content: "\F51D"; }

.mdi-timer-off:before {
  content: "\F51E"; }

.mdi-timer-sand:before {
  content: "\F51F"; }

.mdi-timer-sand-empty:before {
  content: "\F6AC"; }

.mdi-timer-sand-full:before {
  content: "\F78B"; }

.mdi-timetable:before {
  content: "\F520"; }

.mdi-toggle-switch:before {
  content: "\F521"; }

.mdi-toggle-switch-off:before {
  content: "\F522"; }

.mdi-tooltip:before {
  content: "\F523"; }

.mdi-tooltip-edit:before {
  content: "\F524"; }

.mdi-tooltip-image:before {
  content: "\F525"; }

.mdi-tooltip-outline:before {
  content: "\F526"; }

.mdi-tooltip-outline-plus:before {
  content: "\F527"; }

.mdi-tooltip-text:before {
  content: "\F528"; }

.mdi-tooth:before {
  content: "\F8C2"; }

.mdi-tooth-outline:before {
  content: "\F529"; }

.mdi-tor:before {
  content: "\F52A"; }

.mdi-tower-beach:before {
  content: "\F680"; }

.mdi-tower-fire:before {
  content: "\F681"; }

.mdi-towing:before {
  content: "\F83B"; }

.mdi-track-light:before {
  content: "\F913"; }

.mdi-trackpad:before {
  content: "\F7F7"; }

.mdi-trackpad-lock:before {
  content: "\F932"; }

.mdi-tractor:before {
  content: "\F891"; }

.mdi-traffic-light:before {
  content: "\F52B"; }

.mdi-train:before {
  content: "\F52C"; }

.mdi-train-variant:before {
  content: "\F8C3"; }

.mdi-tram:before {
  content: "\F52D"; }

.mdi-transcribe:before {
  content: "\F52E"; }

.mdi-transcribe-close:before {
  content: "\F52F"; }

.mdi-transfer:before {
  content: "\F530"; }

.mdi-transit-transfer:before {
  content: "\F6AD"; }

.mdi-transition:before {
  content: "\F914"; }

.mdi-transition-masked:before {
  content: "\F915"; }

.mdi-translate:before {
  content: "\F5CA"; }

.mdi-treasure-chest:before {
  content: "\F725"; }

.mdi-tree:before {
  content: "\F531"; }

.mdi-trello:before {
  content: "\F532"; }

.mdi-trending-down:before {
  content: "\F533"; }

.mdi-trending-neutral:before {
  content: "\F534"; }

.mdi-trending-up:before {
  content: "\F535"; }

.mdi-triangle:before {
  content: "\F536"; }

.mdi-triangle-outline:before {
  content: "\F537"; }

.mdi-trophy:before {
  content: "\F538"; }

.mdi-trophy-award:before {
  content: "\F539"; }

.mdi-trophy-outline:before {
  content: "\F53A"; }

.mdi-trophy-variant:before {
  content: "\F53B"; }

.mdi-trophy-variant-outline:before {
  content: "\F53C"; }

.mdi-truck:before {
  content: "\F53D"; }

.mdi-truck-delivery:before {
  content: "\F53E"; }

.mdi-truck-fast:before {
  content: "\F787"; }

.mdi-truck-trailer:before {
  content: "\F726"; }

.mdi-tshirt-crew:before {
  content: "\F53F"; }

.mdi-tshirt-v:before {
  content: "\F540"; }

.mdi-tumble-dryer:before {
  content: "\F916"; }

.mdi-tumblr:before {
  content: "\F541"; }

.mdi-tumblr-box:before {
  content: "\F917"; }

.mdi-tumblr-reblog:before {
  content: "\F542"; }

.mdi-tune:before {
  content: "\F62E"; }

.mdi-tune-vertical:before {
  content: "\F66A"; }

.mdi-twitch:before {
  content: "\F543"; }

.mdi-twitter:before {
  content: "\F544"; }

.mdi-twitter-box:before {
  content: "\F545"; }

.mdi-twitter-circle:before {
  content: "\F546"; }

.mdi-twitter-retweet:before {
  content: "\F547"; }

.mdi-uber:before {
  content: "\F748"; }

.mdi-ubuntu:before {
  content: "\F548"; }

.mdi-ultra-high-definition:before {
  content: "\F7F8"; }

.mdi-umbraco:before {
  content: "\F549"; }

.mdi-umbrella:before {
  content: "\F54A"; }

.mdi-umbrella-outline:before {
  content: "\F54B"; }

.mdi-undo:before {
  content: "\F54C"; }

.mdi-undo-variant:before {
  content: "\F54D"; }

.mdi-unfold-less-horizontal:before {
  content: "\F54E"; }

.mdi-unfold-less-vertical:before {
  content: "\F75F"; }

.mdi-unfold-more-horizontal:before {
  content: "\F54F"; }

.mdi-unfold-more-vertical:before {
  content: "\F760"; }

.mdi-ungroup:before {
  content: "\F550"; }

.mdi-unity:before {
  content: "\F6AE"; }

.mdi-untappd:before {
  content: "\F551"; }

.mdi-update:before {
  content: "\F6AF"; }

.mdi-upload:before {
  content: "\F552"; }

.mdi-upload-multiple:before {
  content: "\F83C"; }

.mdi-upload-network:before {
  content: "\F6F5"; }

.mdi-usb:before {
  content: "\F553"; }

.mdi-van-passenger:before {
  content: "\F7F9"; }

.mdi-van-utility:before {
  content: "\F7FA"; }

.mdi-vanish:before {
  content: "\F7FB"; }

.mdi-vector-arrange-above:before {
  content: "\F554"; }

.mdi-vector-arrange-below:before {
  content: "\F555"; }

.mdi-vector-circle:before {
  content: "\F556"; }

.mdi-vector-circle-variant:before {
  content: "\F557"; }

.mdi-vector-combine:before {
  content: "\F558"; }

.mdi-vector-curve:before {
  content: "\F559"; }

.mdi-vector-difference:before {
  content: "\F55A"; }

.mdi-vector-difference-ab:before {
  content: "\F55B"; }

.mdi-vector-difference-ba:before {
  content: "\F55C"; }

.mdi-vector-ellipse:before {
  content: "\F892"; }

.mdi-vector-intersection:before {
  content: "\F55D"; }

.mdi-vector-line:before {
  content: "\F55E"; }

.mdi-vector-point:before {
  content: "\F55F"; }

.mdi-vector-polygon:before {
  content: "\F560"; }

.mdi-vector-polyline:before {
  content: "\F561"; }

.mdi-vector-radius:before {
  content: "\F749"; }

.mdi-vector-rectangle:before {
  content: "\F5C6"; }

.mdi-vector-selection:before {
  content: "\F562"; }

.mdi-vector-square:before {
  content: "\F001"; }

.mdi-vector-triangle:before {
  content: "\F563"; }

.mdi-vector-union:before {
  content: "\F564"; }

.mdi-venmo:before {
  content: "\F578"; }

.mdi-verified:before {
  content: "\F565"; }

.mdi-vibrate:before {
  content: "\F566"; }

.mdi-video:before {
  content: "\F567"; }

.mdi-video-3d:before {
  content: "\F7FC"; }

.mdi-video-4k-box:before {
  content: "\F83D"; }

.mdi-video-account:before {
  content: "\F918"; }

.mdi-video-image:before {
  content: "\F919"; }

.mdi-video-input-antenna:before {
  content: "\F83E"; }

.mdi-video-input-component:before {
  content: "\F83F"; }

.mdi-video-input-hdmi:before {
  content: "\F840"; }

.mdi-video-input-svideo:before {
  content: "\F841"; }

.mdi-video-off:before {
  content: "\F568"; }

.mdi-video-stabilization:before {
  content: "\F91A"; }

.mdi-video-switch:before {
  content: "\F569"; }

.mdi-view-agenda:before {
  content: "\F56A"; }

.mdi-view-array:before {
  content: "\F56B"; }

.mdi-view-carousel:before {
  content: "\F56C"; }

.mdi-view-column:before {
  content: "\F56D"; }

.mdi-view-dashboard:before {
  content: "\F56E"; }

.mdi-view-dashboard-variant:before {
  content: "\F842"; }

.mdi-view-day:before {
  content: "\F56F"; }

.mdi-view-grid:before {
  content: "\F570"; }

.mdi-view-headline:before {
  content: "\F571"; }

.mdi-view-list:before {
  content: "\F572"; }

.mdi-view-module:before {
  content: "\F573"; }

.mdi-view-parallel:before {
  content: "\F727"; }

.mdi-view-quilt:before {
  content: "\F574"; }

.mdi-view-sequential:before {
  content: "\F728"; }

.mdi-view-stream:before {
  content: "\F575"; }

.mdi-view-week:before {
  content: "\F576"; }

.mdi-vimeo:before {
  content: "\F577"; }

.mdi-violin:before {
  content: "\F60F"; }

.mdi-virtual-reality:before {
  content: "\F893"; }

.mdi-visualstudio:before {
  content: "\F610"; }

.mdi-vk:before {
  content: "\F579"; }

.mdi-vk-box:before {
  content: "\F57A"; }

.mdi-vk-circle:before {
  content: "\F57B"; }

.mdi-vlc:before {
  content: "\F57C"; }

.mdi-voice:before {
  content: "\F5CB"; }

.mdi-voicemail:before {
  content: "\F57D"; }

.mdi-volume-high:before {
  content: "\F57E"; }

.mdi-volume-low:before {
  content: "\F57F"; }

.mdi-volume-medium:before {
  content: "\F580"; }

.mdi-volume-minus:before {
  content: "\F75D"; }

.mdi-volume-mute:before {
  content: "\F75E"; }

.mdi-volume-off:before {
  content: "\F581"; }

.mdi-volume-plus:before {
  content: "\F75C"; }

.mdi-vpn:before {
  content: "\F582"; }

.mdi-vuejs:before {
  content: "\F843"; }

.mdi-walk:before {
  content: "\F583"; }

.mdi-wall:before {
  content: "\F7FD"; }

.mdi-wall-sconce:before {
  content: "\F91B"; }

.mdi-wall-sconce-flat:before {
  content: "\F91C"; }

.mdi-wall-sconce-variant:before {
  content: "\F91D"; }

.mdi-wallet:before {
  content: "\F584"; }

.mdi-wallet-giftcard:before {
  content: "\F585"; }

.mdi-wallet-membership:before {
  content: "\F586"; }

.mdi-wallet-travel:before {
  content: "\F587"; }

.mdi-wan:before {
  content: "\F588"; }

.mdi-washing-machine:before {
  content: "\F729"; }

.mdi-watch:before {
  content: "\F589"; }

.mdi-watch-export:before {
  content: "\F58A"; }

.mdi-watch-export-variant:before {
  content: "\F894"; }

.mdi-watch-import:before {
  content: "\F58B"; }

.mdi-watch-import-variant:before {
  content: "\F895"; }

.mdi-watch-variant:before {
  content: "\F896"; }

.mdi-watch-vibrate:before {
  content: "\F6B0"; }

.mdi-water:before {
  content: "\F58C"; }

.mdi-water-off:before {
  content: "\F58D"; }

.mdi-water-percent:before {
  content: "\F58E"; }

.mdi-water-pump:before {
  content: "\F58F"; }

.mdi-watermark:before {
  content: "\F612"; }

.mdi-waves:before {
  content: "\F78C"; }

.mdi-weather-cloudy:before {
  content: "\F590"; }

.mdi-weather-fog:before {
  content: "\F591"; }

.mdi-weather-hail:before {
  content: "\F592"; }

.mdi-weather-hurricane:before {
  content: "\F897"; }

.mdi-weather-lightning:before {
  content: "\F593"; }

.mdi-weather-lightning-rainy:before {
  content: "\F67D"; }

.mdi-weather-night:before {
  content: "\F594"; }

.mdi-weather-partlycloudy:before {
  content: "\F595"; }

.mdi-weather-pouring:before {
  content: "\F596"; }

.mdi-weather-rainy:before {
  content: "\F597"; }

.mdi-weather-snowy:before {
  content: "\F598"; }

.mdi-weather-snowy-rainy:before {
  content: "\F67E"; }

.mdi-weather-sunny:before {
  content: "\F599"; }

.mdi-weather-sunset:before {
  content: "\F59A"; }

.mdi-weather-sunset-down:before {
  content: "\F59B"; }

.mdi-weather-sunset-up:before {
  content: "\F59C"; }

.mdi-weather-windy:before {
  content: "\F59D"; }

.mdi-weather-windy-variant:before {
  content: "\F59E"; }

.mdi-web:before {
  content: "\F59F"; }

.mdi-webcam:before {
  content: "\F5A0"; }

.mdi-webhook:before {
  content: "\F62F"; }

.mdi-webpack:before {
  content: "\F72A"; }

.mdi-wechat:before {
  content: "\F611"; }

.mdi-weight:before {
  content: "\F5A1"; }

.mdi-weight-kilogram:before {
  content: "\F5A2"; }

.mdi-whatsapp:before {
  content: "\F5A3"; }

.mdi-wheelchair-accessibility:before {
  content: "\F5A4"; }

.mdi-white-balance-auto:before {
  content: "\F5A5"; }

.mdi-white-balance-incandescent:before {
  content: "\F5A6"; }

.mdi-white-balance-iridescent:before {
  content: "\F5A7"; }

.mdi-white-balance-sunny:before {
  content: "\F5A8"; }

.mdi-widgets:before {
  content: "\F72B"; }

.mdi-wifi:before {
  content: "\F5A9"; }

.mdi-wifi-off:before {
  content: "\F5AA"; }

.mdi-wifi-strength-1:before {
  content: "\F91E"; }

.mdi-wifi-strength-1-alert:before {
  content: "\F91F"; }

.mdi-wifi-strength-1-lock:before {
  content: "\F920"; }

.mdi-wifi-strength-2:before {
  content: "\F921"; }

.mdi-wifi-strength-2-alert:before {
  content: "\F922"; }

.mdi-wifi-strength-2-lock:before {
  content: "\F923"; }

.mdi-wifi-strength-3:before {
  content: "\F924"; }

.mdi-wifi-strength-3-alert:before {
  content: "\F925"; }

.mdi-wifi-strength-3-lock:before {
  content: "\F926"; }

.mdi-wifi-strength-4:before {
  content: "\F927"; }

.mdi-wifi-strength-4-alert:before {
  content: "\F928"; }

.mdi-wifi-strength-4-lock:before {
  content: "\F929"; }

.mdi-wifi-strength-alert-outline:before {
  content: "\F92A"; }

.mdi-wifi-strength-lock-outline:before {
  content: "\F92B"; }

.mdi-wifi-strength-off:before {
  content: "\F92C"; }

.mdi-wifi-strength-off-outline:before {
  content: "\F92D"; }

.mdi-wifi-strength-outline:before {
  content: "\F92E"; }

.mdi-wii:before {
  content: "\F5AB"; }

.mdi-wiiu:before {
  content: "\F72C"; }

.mdi-wikipedia:before {
  content: "\F5AC"; }

.mdi-window-close:before {
  content: "\F5AD"; }

.mdi-window-closed:before {
  content: "\F5AE"; }

.mdi-window-maximize:before {
  content: "\F5AF"; }

.mdi-window-minimize:before {
  content: "\F5B0"; }

.mdi-window-open:before {
  content: "\F5B1"; }

.mdi-window-restore:before {
  content: "\F5B2"; }

.mdi-windows:before {
  content: "\F5B3"; }

.mdi-wordpress:before {
  content: "\F5B4"; }

.mdi-worker:before {
  content: "\F5B5"; }

.mdi-wrap:before {
  content: "\F5B6"; }

.mdi-wrench:before {
  content: "\F5B7"; }

.mdi-wunderlist:before {
  content: "\F5B8"; }

.mdi-xamarin:before {
  content: "\F844"; }

.mdi-xamarin-outline:before {
  content: "\F845"; }

.mdi-xaml:before {
  content: "\F673"; }

.mdi-xbox:before {
  content: "\F5B9"; }

.mdi-xbox-controller:before {
  content: "\F5BA"; }

.mdi-xbox-controller-battery-alert:before {
  content: "\F74A"; }

.mdi-xbox-controller-battery-empty:before {
  content: "\F74B"; }

.mdi-xbox-controller-battery-full:before {
  content: "\F74C"; }

.mdi-xbox-controller-battery-low:before {
  content: "\F74D"; }

.mdi-xbox-controller-battery-medium:before {
  content: "\F74E"; }

.mdi-xbox-controller-battery-unknown:before {
  content: "\F74F"; }

.mdi-xbox-controller-off:before {
  content: "\F5BB"; }

.mdi-xda:before {
  content: "\F5BC"; }

.mdi-xing:before {
  content: "\F5BD"; }

.mdi-xing-box:before {
  content: "\F5BE"; }

.mdi-xing-circle:before {
  content: "\F5BF"; }

.mdi-xml:before {
  content: "\F5C0"; }

.mdi-xmpp:before {
  content: "\F7FE"; }

.mdi-yammer:before {
  content: "\F788"; }

.mdi-yeast:before {
  content: "\F5C1"; }

.mdi-yelp:before {
  content: "\F5C2"; }

.mdi-yin-yang:before {
  content: "\F67F"; }

.mdi-youtube:before {
  content: "\F5C3"; }

.mdi-youtube-creator-studio:before {
  content: "\F846"; }

.mdi-youtube-gaming:before {
  content: "\F847"; }

.mdi-youtube-tv:before {
  content: "\F448"; }

.mdi-zip-box:before {
  content: "\F5C4"; }

.mdi-blank:before {
  content: "\F68C";
  visibility: hidden; }

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px; }

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px; }

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px; }

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px; }

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54); }

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26); }

.mdi-light:before {
  color: white; }

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3); }

.mdi-rotate-45 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(45deg);
      transform: scaleX(-1) rotate(45deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: scaleY(-1) rotate(45deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.mdi-rotate-90 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(90deg);
      transform: scaleX(-1) rotate(90deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: scaleY(-1) rotate(90deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.mdi-rotate-135 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(135deg);
      transform: scaleX(-1) rotate(135deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: scaleY(-1) rotate(135deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg); }

.mdi-rotate-180 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(180deg);
      transform: scaleX(-1) rotate(180deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: scaleY(-1) rotate(180deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.mdi-rotate-225 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(225deg);
      transform: scaleX(-1) rotate(225deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: scaleY(-1) rotate(225deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg); }

.mdi-rotate-270 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(270deg);
      transform: scaleX(-1) rotate(270deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: scaleY(-1) rotate(270deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.mdi-rotate-315 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(315deg);
      transform: scaleX(-1) rotate(315deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: scaleY(-1) rotate(315deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */ }

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg); }

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV"; }

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear; }

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/*# sourceMappingURL=materialdesignicons.css.map */
